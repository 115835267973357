import { Typography, Box } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../resources/styles/NancyDefaultTheme.css"

export default function NancyNotFoundPage() {
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout(() => {
            navigate("/")
        }, 2000);
    }, []);
    return (
        <Box className="errorBlock">
            <Typography variant="h1">Error 404</Typography>
            <Typography variant="h2">Page not found</Typography>
        </Box>
    );
};
