import React from "react";
import { Typography } from "@mui/material";

export default function NancyNoteMetaInfo({ NancyNoteMetaInfo }) {

    return (
        <>
            <Typography
                variant="subtitle2"
                component="span"
                sx={{ fontWeight: "bold", mr: 1 }}
            >
                Created by
            </Typography>
            <Typography variant="subtitle2" component="span" sx={{ mr: 1 }}>
                {NancyNoteMetaInfo.author}
            </Typography>
            <Typography
                variant="subtitle2"
                component="span"
                sx={{ fontWeight: "bold", mr: 1 }}
            >
                on
            </Typography>
            <Typography variant="subtitle2" component="span" sx={{ mr: 2 }}>
                {NancyNoteMetaInfo.created}
            </Typography>
            <Typography
                variant="subtitle2"
                component="span"
                sx={{ fontWeight: "bold", mr: 1 }}
            >
                Last updated
            </Typography>
            <Typography
                variant="subtitle2"
                component="span"
                sx={{ fontWeight: "bold", mr: 1 }}
            >
                on
            </Typography>
            <Typography variant="subtitle2" component="span">
                {NancyNoteMetaInfo.updated}
            </Typography>
        </>
    );
} // End Functional Component <NancyNoteMetaInfo>
