import React, { useState, useContext ,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Divider, List } from "@mui/material";
import { AzureAuthContext } from "../azure-components/AzureUserInfo";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';

export default function NancyUserPopupMenu() {
    const { instance, userIsLoggedIn, accountInfo } = useContext(AzureAuthContext);
    const navigator = useNavigate();
    // State for managing the settings menu
    const [anchorElement, setAnchorElement] = useState(null);
    const open = Boolean(anchorElement);

    const handleAzureLogin = () => {
        instance.loginPopup().catch((error) => {
            console.error(error);
        });
    };

    // Open the settings menu
    const handleUserMenuClick = (event) => {
        setAnchorElement(event.currentTarget);
    };

    // Close the settings menu
    const handleUserMenuClose = () => {
        setAnchorElement(null);
    };

    const handleUserLogout = () => {
        instance.logout();
        navigator("/logout");
    };
  
    const loggedInUserMenu = (
        <Menu
            className="NancyPopupMenu"
            anchorEl={anchorElement}
            open={open}
            onClose={handleUserMenuClose}
            onClick={handleUserMenuClose}
            PaperProps={{ elevation: 4 }}
        >
            <MenuItem onClick={() => navigator("/user/profile")}>
                <ListItemIcon>
                    <AccountCircleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Profile" />
            </MenuItem>
            <MenuItem onClick={() => navigator("/user/settings")}>
                <ListItemIcon>
                    <SettingsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Settings" />
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleUserLogout}>
                <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </MenuItem>
        </Menu>
    );

    const guestUserMenu = (
        <Menu className="NancyPopupMenu"
            anchorEl={anchorElement}
            open={open}
            onClose={handleUserMenuClose}
            onClick={handleUserMenuClose}
            PaperProps={{ elevation: 4, }}
        >
            <MenuItem onClick={handleAzureLogin}>
                <ListItemIcon>
                    <LoginIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Login" />
            </MenuItem>
        </Menu>
    );

    return (
        <>
            <IconButton
                sx={{
                    p: 0,
                    border: '0',
                    boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)',
                    ':hover': {
                        "&::after": {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            borderRadius: '50%',
                            backgroundColor: 'rgba(255,105,180,0.2)', // semi-transparent pink
                            zIndex: 1,
                        }
                    }
                }}
                onClick={handleUserMenuClick}
            >
            <Avatar alt="Nancy" sx={{backgroundColor: "#CFDEEC"}}>
                <AccountCircleIcon sx={{ color: "#1D354A" }} />
            </Avatar>
        </IconButton>
        {userIsLoggedIn ? loggedInUserMenu : guestUserMenu}
    </>
    );
}
