import React from 'react'
import { Box, Typography } from "@mui/material";

export default function NancyUserPage() {
  return (
    <div className="NancyUserSettings">
        <Box sx={{ marginBottom: '20px', flexGrow: 1, display: { xs: 'none', md: 'flex' } }} style={{ borderBottom: '2px solid black' }} >
            <Typography variant='h4' gutterBottom>Settings</Typography>
        </Box>
        <Typography variant='paragraph'>
            No setings available yet.
        </Typography>
    </div>
  );
};
