/**
 * File: AzureFunctionAPICallBase.js
 * The purpose of this file is to export functions that wrap the low level
 * https calls to the Azure Functions Nancy API backend.
 */

import { AzureFuncAPIRequest } from '../configurations/NancyMSALAuthConfig';
import { MSALInstance } from '../index'; // We need the MSALInstance instansiated in index.


/**
 * Get the base64 encoded Azure access token from the MSFT MSAL library available after
 * use login to the application using Azure AD. Necessary to call an AAD protected Azure Funcion.
 * @returns Base64 encoded azure access token.
 */
export async function AzureBearerToken() {
    const account = MSALInstance.getActiveAccount()  
    if (!account) {
        let msg = 'No active account! Verify a user has been signed in and setActiveAccount has been called.';
        //console.log(msg);
        throw Error(msg);
    }
    const response = await MSALInstance.acquireTokenSilent({
      ...AzureFuncAPIRequest,
      account: account,
    })
    if(!response) {
        let msg = 'No response object from MSALInstance.acquireTokenSilent.';
        //console.log(msg);
        throw Error(msg);
    }
    return response.accessToken
} // End fu AzureBearerToken


/**
 * Performs an API call to the Azure Function App with given parameters.
 *
 * @param {Object} param0 - An object containing the API call configuration.
 * @param {string} param0.endpoint - The API endpoint to call (relative to the Azure Function App base URL).
 * @param {Object} [param0.queryParams={}] - An object representing the query parameters to include in the request URL.
 * @param {string} [param0.method='GET'] - The HTTP method to use for the request (e.g., 'GET', 'POST', 'PUT', 'DELETE').
 * @param {Object} [param0.body=null] - The JSON object to send as the request body (if applicable).
 * @param {AbortSignal} [param0.signal=null] - An AbortSignal to control the fetch request (e.g., for canceling the request to avoid race conditions).
 * @returns {Promise<Object|null>} A promise that resolves to the response JSON object on success or null on error.
 * 
 * @throws {Error} If an error occurs during the fetch request (e.g., network error, invalid URL).
 */
export async function AzureBaseAPICall({endpoint, queryParams = {}, method = 'GET', body = null, signal=null}) {
    // Build the query string from the queryParams object
    const queryString = Object.entries(queryParams).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');
    const token = await AzureBearerToken();
  
    // Add the query string to the endpoint URL if it is not empty
    const url = queryString ? `${AzureFuncAPIRequest.url}/${endpoint}?${queryString}` : `${AzureFuncAPIRequest.url}${endpoint}`;
    
    // Ensure API endpoint uses HTTPS
    if (!AzureFuncAPIRequest.url.startsWith('https://')) {
        throw new Error('[AzureBaseAPICall] API endpoint must use HTTPS');
    }

  
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
  
    headers.append('Authorization', bearer);
    headers.append('Content-Type', 'application/json');
  
    const options = {
      method: method,
      headers: headers,
      body: body ? JSON.stringify(body) : null,
      signal: signal
    };
    
    try {
        //console.log("[AzureBaseAPICall] Options:", options)
        const response = await fetch(url, options);
        if( ! response.ok ) {
            const error = `[AzureBaseAPICall] Fetch failed (${response.status}): ${response.statusText}`;
            console.error(error);
            console.error(`URL: ${response.url}`);
            console.error(`Headers: ${JSON.stringify(response.headers)}`);
            const responseBody = await response.text();
            console.error(`Body: ${responseBody}`);
            //throw new Error(`[AzureBaseAPICall] Fetch failed (${response.status}): ${response.statusText}`);
            return response;
        }
        const json = await response.json();
        //console.log("[AzureBaseAPICall] Response:", json);
        return json;
    } catch(error) {
        //console.error("[AzureBaseAPICall] Error while calling fetch.", error);
        // TODO: Improve error handling!!!
        return null;
    }
} // End fu AzureBaseAPICall
