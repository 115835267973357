import { createContext, useContext, useState, useEffect } from "react";
import { NancyNotesBackend_ListNotes } from '../services/NancyNotesBackendInterface';
import { AzureAuthContext } from "../../../components/azure-components/AzureUserInfo";

// Create and export a new context for managing Nancy application's note-list:
export const NancyNotesDataContext = createContext();
export const useNancyNotesDataContext = () => useContext(NancyNotesDataContext);

/**
 * A higher-order component that provides a list of notes and virtual path suggestions to child components.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - The child components wrapped by the NancyAuthProvider.
 * @returns {JSX.Element} The NancyNotesDataProvider component that wraps the children with the provided data context.
 */
export const NancyNotesDataProvider = ({ children }) => {
    //const [documents, setDocuments] = useState(null);
    const [NancyNotes, setNancyNotes] = useState(null);
    const [NancyNotesPathSuggestions, setNancyNotesPathSuggestions] = useState(null);
    const [NancyNotesTree, setNancyNotesTree] = useState(null);
    const [NancyNotesLookupById, setNancyNotesLookupById] = useState(null);
    const { userIsLoggedIn } = useContext(AzureAuthContext);

    // Data load helper function.
    const loadDocumentsFromDB = async () => {
        const response = await NancyNotesBackend_ListNotes({});
        //console.log(JSON.stringify(response, null, 2));
        const documents = response.result.documents; // This should be fixed at ListNotes...
        return documents;
    }; // End fu loadDocumentsFromDB

    // Build the path suggestions dict:
    const buildVirtualPathSuggestions = (documents) => {
        if (!documents || !Array.isArray(documents) || 0 === documents.length) {
            return null;
        }
        const virtualPathSuggestions = {};
        documents.forEach((doc) => {
            const path = doc.path; // NB: Sometimes empty on older data
            if (path && path.trim() !== "" && path.trim() !== "/") {
                const parts = path
                    .split("/")
                    .filter((part) => part.trim() !== "");
                let currentObj = virtualPathSuggestions;
                parts.forEach((part) => {
                    if (!currentObj[part]) {
                        currentObj[part] = {};
                    }
                    currentObj = currentObj[part];
                });
            }
        });
        return virtualPathSuggestions;
    }; // End fu buildVirtualPathSuggestions
    
    // This function will build a tree structure from the given notes
    const buildNotesTree = (notes) => {
        const root = { type: "root", name: "root", path: "/", children: {} };
        notes.forEach((note) => {
            let path = note.path;
            const parts = path.split("/").filter((part) => part.trim() !== "");
            let currentObj = root["children"];
            let currentPath = "";
            parts.forEach((part, index) => {
                currentPath += "/" + part;
                if (!currentObj[part]) {
                    currentObj[part] = {
                        type: "folder",
                        name: part,
                        path: currentPath,
                        children: {},
                    };
                }
                currentObj = currentObj[part]["children"];
            }); // foreach part
            currentObj[note._id] = {
                type: "note",
                name: note.title,
                id: note._id,
                path: note.path,
                author: note.aad_user_email,
                created: note.created_at,
                updated: note.updated_at,
            };
        });

        // Recursive function to sort the children at each level of the tree
        const sortChildren = (node) => {
            const keys = Object.keys(node.children);
            keys.sort((a, b) => node.children[a].name.localeCompare(node.children[b].name));
            node.children = keys.reduce((obj, key) => {
                obj[key] = node.children[key];
                if (node.children[key].type === "folder") {
                    sortChildren(node.children[key]);
                }
                return obj;
            }, {});
        };
        sortChildren(root);
        return root;
    }; // End fu buildNotesTree
    

    const buildNancyNotesLookupById = (documents) => {
        if (!documents || !Array.isArray(documents) || 0 === documents.length) {
            return null;
        }
        const lookupTable = {};
        documents.forEach((doc) => {
            if (doc._id && doc.title && doc.path) {
                if (!lookupTable[doc._id]) {
                    lookupTable[doc._id] = {
                        title: doc.title,
                        path: doc.path,
                    };
                }
            }
        });
        return lookupTable;
    }; // End fu buildNancyNotesLookupById

    const fetchNotesData = async () => {
        try {
            const docs = await loadDocumentsFromDB();
            //console.log("[NancyNotesDataProvider::DEBUG] Loaded documents from db:", docs);
            setNancyNotes(docs);
        } catch (error) {
            console.error(
                "[NancyNotesDataProvider::ERROR] Unable to load documents on component mount:",
                error
            );
        }
    };

    // Run once on component mount:
    useEffect(() => {
        if (userIsLoggedIn) {
            fetchNotesData(); // Load documents from db
        }
    }, []);
    // Run if the userIsLoggedIn var changes:
    useEffect(() => {
        if (userIsLoggedIn) {
            fetchNotesData(); // Load documents from db
        }
    }, [userIsLoggedIn]);

    // Hook for when NancyNotes are loaded:
    useEffect(() => {
        // Build virtual path suggestions:
        try {
            // Don't do anything until NancyNotes is updated to be different from the initialized "null" value:
            if (null !== NancyNotes) {
                const lookup = buildNancyNotesLookupById(NancyNotes);
                if ("[object Object]" === Object.prototype.toString.call(lookup)) {
                    setNancyNotesLookupById(lookup);
                }
                // Build the path suggestions dict:
                const suggestions = buildVirtualPathSuggestions(NancyNotes);
                if ("[object Object]" === Object.prototype.toString.call(suggestions)) {
                    setNancyNotesPathSuggestions(suggestions);
                }
                // Build a tree structure from the notes:
                const tree = buildNotesTree(NancyNotes);
                if ("[object Object]" === Object.prototype.toString.call(tree)) {
                    setNancyNotesTree(tree);
                }
            }
        } catch (error) {
            console.error(
                "[NancyNotesDataProvider::ERROR] Unable to build and set virtual path suggestions:",
                error
            );
        }
    }, [NancyNotes]);

    const NancyNotesAddNote = (newNote) => {
        //console.log("[NancyNotesDataProvider] NancyNotesAddNote:", newNote)
        //Add the new note to the documents state
        setNancyNotes((prevDocuments) => {
            const updatedDocuments = [...prevDocuments, newNote];
            return updatedDocuments;
        });
    }; // End fu NancyNotesAddNote

    const NancyNotesRemoveNote = (id) => {
        //console.log("[NancyNotesDataProvider] NancyNotesRemoveNote:", id)
        //Add the new note to the documents state
        setNancyNotes((prevDocuments) => {
            //const updatedDocuments = [...prevDocuments, newNote];
            const updatedDocuments = prevDocuments.filter(note => note._id !== id);
            return updatedDocuments;
        });
    }; // End fu NancyNotesAddNote

    // Add a note dynamically to the notes data structure
    // without having to reload the entire notes data structure.
    // For now used only when a new note is created at the frontend,
    // but could be used to also check for new notes at the backend.
    const NancyNotesUpdateNotePath = (noteId, updatedPath) => {
        // Update the document's path in the documents state
        setNancyNotes((prevDocuments) => {
            const updatedDocuments = prevDocuments.map((doc) => {
                if (doc._id === noteId) {
                    return { ...doc, path: updatedPath };
                }
                return doc;
            });
            return updatedDocuments;
        });
    }; // End fu updateNotePath

    const NancyNotesUpdateNoteTitle = (noteId, updatedTitle) => {
        // Update the document's path in the documents state
        setNancyNotes((prevDocuments) => {
            const updatedDocuments = prevDocuments.map((doc) => {
                if (doc._id === noteId) {
                    return { ...doc, title: updatedTitle };
                }
                return doc;
            });
            return updatedDocuments;
        });
    }; // End fu NancyNotesUpdateNoteTitle

    const NancyNoteGetMetaById = (noteId) => {
        try {
            return NancyNotesLookupById[noteId];
        } catch (error) {
            return null;
        }
    }; // End fu NancyNoteGetMetaById

    // Wrap the child components with the <NancyNotesContext.Provider> and pass down ...:
    return (
        <NancyNotesDataContext.Provider
            value={{
                NancyNotes,
                NancyNotesTree,
                NancyNotesPathSuggestions,
                NancyNotesAddNote,
                NancyNotesUpdateNotePath,
                NancyNotesUpdateNoteTitle,
                NancyNoteGetMetaById,
                NancyNotesRemoveNote
            }}
        >
            {children}
        </NancyNotesDataContext.Provider>
    );
};
