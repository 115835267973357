import { createContext, useContext, useState } from "react";

// Exported functions to update the status message:
// - updateGlobalNancyStatusMessage
// - updateTransientNancyStatusMessage
// Usage:
//   import { useStatusContext } from "../nancy-contexts/NancyStatusContex";
//   const MyComponent = () => {
//     const { updateGlobalNancyStatusMessage, updateTransientNancyStatusMessage } = useNancyStatusContext();
//     updateGlobalStatusMessage("Some message");
//     ...
//   }
// Exported variable to display the current status message:
//  - useNancyStatusContext
// Usage:
//   import { useNancyStatusContext, NANCY_STATUS } from "../nancy-contexts/NancyStatusContex";
//   export default function MyComponent() {
//     const { userIsLoggedIn, accountInfo } = useContext(AzureAuthContext);
//     const { currentNancyStatusMessage } = useNancyStatusContext();
//     return (
//       <Container>
//          {currentNancyStatusMessage}
//       </Container>
//     );
// }

const NancyStatusContext = createContext();

export const useNancyStatusContext = () => useContext(NancyStatusContext);
export const NANCY_STATUS = {
    INFO: "info",
    WAITING: "waiting",
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "error",
    DEFAULT: "info"
}

const NANCY_DEFAULT_MESSAGE = "Nancy App Ready";

export const NancyStatusProvider = ({ children }) => {
    const [NancyOverallStatus, setNancyOverallStatus] = useState({message: NANCY_DEFAULT_MESSAGE, type: NANCY_STATUS.INFO});
    const [NancyTransientStatus, setNancyTransientStatus] = useState(null);

    const updateGlobalNancyStatusMessage = ({message, type = NANCY_STATUS.INFO}) => {
      setNancyOverallStatus({message, type});
    };

    const updateTransientNancyStatusMessage = (message, type = NANCY_STATUS.INFO, duration = 5000) => {
      setNancyTransientStatus({message, type});
      setTimeout(() => {
          setNancyTransientStatus(null);
      }, duration);
    };

    const currentNancyStatus = NancyTransientStatus || NancyOverallStatus || NANCY_DEFAULT_MESSAGE;

    return (
      <NancyStatusContext.Provider value={{
          currentNancyStatusMessage: currentNancyStatus.message,
          currentNancyStatusType: currentNancyStatus.type,
          updateGlobalNancyStatusMessage,
          updateTransientNancyStatusMessage
      }}>
          {children}
        </NancyStatusContext.Provider>
    );
};
