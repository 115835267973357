import React from 'react';
import { TreeItem } from '@mui/lab';
import FolderIcon from '@mui/icons-material/Folder';
import NancyNotesListItem from './NancyNotesListItem';
import { NancyNotesBackend_UpdateNoteVirtualPath } from "../../services/NancyNotesBackendInterface";
import { useNancyStatusContext, NANCY_STATUS } from "../../../../components/nancy-contexts/NancyStatusContex";
import { useNancyNotesDataContext } from "../../contexts/NancyNotesContext";

/**
 *
 * @returns {JSX.Element} Returns a list of links to notes.
 */

export default function NancyNotesListFolder({ type, path, name, children, level=0 }) {
    // Use the global Nancy Status Context to update the status field in the footer when updating the title:
    const { updateTransientNancyStatusMessage } = useNancyStatusContext();
    const { NancyNotesUpdateNotePath } = useNancyNotesDataContext();

    const handleNoteDrop = async (data, newPath) => {
        //console.log("handleNoteDrop", data);
        const newPathArray = newPath.split('/').filter(Boolean);
        const res = await NancyNotesBackend_UpdateNoteVirtualPath(data.id, newPathArray);
        if( res ) {
            updateTransientNancyStatusMessage("Path saved", NANCY_STATUS.SUCCESS);
            NancyNotesUpdateNotePath(data.id, newPath);
        }
        else {
            updateTransientNancyStatusMessage("Path unexpectedly NOT saved", NANCY_STATUS.ERROR);
        }
    } // End function handleNoteDrop

    return (
      <TreeItem
        nodeId={path}
        label={
          <div
              onDragOver={(e) => {
                  e.preventDefault();
                  e.dataTransfer.dropEffect = 'move';
              }}
              onDrop={(e) => {
                  e.preventDefault();
                  const data = JSON.parse(e.dataTransfer.getData('application/my-app'));
                  handleNoteDrop(data, path);
              }}
          >
            <FolderIcon />
            <span style={{ marginLeft: "4px" }}>{name}</span>
          </div>
        }
      >
        {children && Object.values(children).map((child) =>
          child.type === "folder" ? (
            <NancyNotesListFolder
              key={'f_' + child.path}
              type="folder"
              path={child.path}
              name={child.name}
              children={child.children}
            />
        ) : (
            <NancyNotesListItem key={'n_' + child.id} item={child} />
          )
        )}
      </TreeItem>
    ); // End return statement
  } // End component NancyNotesListFolder

  