import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Typography, Box } from "@mui/material";
import { NancyNoteSearchWidget, NancyNoteCreateNewNoteWidget} from "../../modules/NancyNotes";
import NancyUserPopupMenu from "../nancy-custom-ux-components/NancyUserPopupMenu";
import NancyNavigationPopupMenu from "../nancy-custom-ux-components/NancyNavigationPopupMenu";
/**
 *
 * Renders the header component with a navigation menu, page title, and settings button.
 * @returns {JSX.Element} The NancyHeader component.
 */
export default function NancyHeader() {
    const navigator = useNavigate();

    return(
        <header className="NancyHeader">   
            <AppBar position="static">
                <Toolbar className="NancyToolbar">
                    <NancyNavigationPopupMenu />
                    <Typography
                        variant="h4"
                        className="NancyHeaderTitle"
                        onClick={() => {navigator("/")}}
                        sx={{
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                    >
                        Nancy <span>App</span><sub>BETA</sub>
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} style={{ border: '0px solid lightblue' }} /* Spacer to push the Icon menu to the right */ />
                    <NancyNoteSearchWidget />
                    <NancyNoteCreateNewNoteWidget />
                    <Box sx={{ flexGrow: 0 }}>
                        <NancyUserPopupMenu />
                    </Box>
                </Toolbar>
            </AppBar>
        </header>
    );
};
