import React, { useContext } from 'react';
import { AzureAuthContext } from "../azure-components/AzureUserInfo";
import NancyHeader from './NancyHeader';
import NancySidebar from './NancySidebar';
import NancyContentPane from './NancyContentPane';
import NancyFooter from './NancyFooter';
import '../../resources/styles/NancyGrid.css';
import NancyDefaultPage from '../nancy-pages/NancyDefaultPage';
import { Box } from '@mui/system';

/**
 * Renders a fixed grid layout with a header, sidebar, content area, and footer.
 * Content area fills the part of the screen not occupied by the three other areas.
 * @returns {JSX.Element} The NancyGrid component.
 */
export default function NancyGrid() {
    const { userIsLoggedIn } = useContext(AzureAuthContext);
    if (!userIsLoggedIn) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <NancyDefaultPage />
            </Box>
        );
    }
    return (
        <div style={{
            height: '100vh',
            padding: 0,
            margin: 0,
            display: 'grid',
            gridTemplateColumns: 'var(--sidebar-width, 240px) 1fr',
            gridTemplateRows: 'auto 1fr auto',
            gridTemplateAreas: '"header header" "sidebar content" "footer footer"'
        }}>
            <div style={{ gridArea: 'header', position: 'sticky', top: 0, zIndex: 1 }}><NancyHeader /></div>
            <div style={{ gridArea: 'sidebar', position: 'sticky', overflowY: 'auto', overflowX: 'hidden' }} className='NancySidebarContainer'><NancySidebar /></div>
            <div style={{ gridArea: 'content', overflowY: 'auto'  }}><NancyContentPane /></div>
            <div style={{ gridArea: 'footer', position: 'sticky', bottom: 0, zIndex: 1 }}><NancyFooter /></div>
        </div>
    );
};
