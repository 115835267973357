import React from 'react';
import { ListItemButton } from '@mui/material';
import NancyNavLink from './NancyNavLink';


/**
 * This code block demonstrates how to create an isActive effect on
 * menu buttons using React v19, React Router v6 and Material-UI v5.
 * For more information and examples, refer to the following resources:
 * https://www.youtube.com/watch?v=uFSu6tgYKRY
 * https://stackoverflow.com/questions/71350481/react-router-dom-v6-navlink-and-mui-listitem-not-working-with-classname
 */

// Custom ListItemButton component that wraps ListItemButton from Material-UI.
// It uses the custom NavLink component as its default component, and sets activeClassName and className for styling.
const NancyListItemButton = React.forwardRef(({ ...props }, ref) => (
    <ListItemButton
        ref={ref}                                       // React ref to the underlying DOM
        component={NancyNavLink}                        // All the ListItemButton elements should be a NavLink
        activeClassName="SidebarListItemButtonActive"   // Defined in NancyDefaultTheme.css
        className="SidebarListItemButton"               // Defined in NancyDefaultTheme.css
        {...props}                                      // Passing on all other props
    >
        {props.children}
    </ListItemButton>
));

export { NancyListItemButton as default };
