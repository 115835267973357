import NancyGrid from './components/nancy-grid/NancyGrid';

/**
 * 
 * @returns 
 */
export default function App() {
    return (
        <NancyGrid />
    );
};
