import React, { useEffect } from 'react';
import { Typography } from "@mui/material";
import { useNancyNotesDataContext } from "../../contexts/NancyNotesContext";
import { TreeView } from '@mui/lab';
import NancyNotesListFolder from './NancyNotesListFolder';
import NancyNotesListItem from './NancyNotesListItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

/**
 * NancyNotesList renders a list of notes for the sidebar navigation pane.
 * It retrieves the list of notes from the NancyNotesDataContext and maps
 * each note to a NancyListItemButton component. The component displays the
 * note title as the primary text and the note path as the secondary text.
 *
 * When the list of notes is not initialized or is not an array, the component
 * displays a loading placeholder.
 *
 * @returns {JSX.Element} Returns a list of links to notes.
 */
export default function NancyNotesListTree() {
    // Load expanded state from local storage or set to an empty array if none exists
    const [selected, setSelected] = React.useState([]);
    const [expanded, setExpanded] = React.useState(() => {
        const saved = localStorage.getItem("expandedFolders");
        return saved ? JSON.parse(saved) : [];
    });
    const { NancyNotesTree } = useNancyNotesDataContext();

    const folders = [];
    const notes = [];

    useEffect(() => {
        // When the expanded state changes, save it to local storage
        localStorage.setItem("expandedFolders", JSON.stringify(expanded));
    }, [expanded]);

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };
    
    const handleSelect = (event, nodeIds) => {
        setSelected(nodeIds);
    };
    
    // If the list of notes hasn't been initialized, return a loading placeholder:
    if (! NancyNotesTree ||  "[object Object]" !== Object.prototype.toString.call(NancyNotesTree) ) {
        return(
            <Typography>Notes Tree Loading...</Typography>
        );
    }

    Object.values(NancyNotesTree.children).forEach((item) => {
        if (item.type === "folder") {
            //console.log("[NancyNotesListTree::DEBUG] folder key: " + item.path);
            folders.push(
                <NancyNotesListFolder
                    key={'rf_' + item.path} // root folder
                    type={item.type}
                    path={item.path}
                    name={item.name}
                    children={item.children}
                />
            );
        } else if (item.type === "note") {
            //console.log("[NancyNotesListTree::DEBUG] note key: " + item.id);
            notes.push(
                <NancyNotesListItem key={"rn_" + item.id} item={item} /> // root note
            );
        }
    });
    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
        >
            {folders}
            {/* {folders.length > 0 && notes.length > 0 && <Divider />} */}
            {notes}
        </TreeView>
    );
  } // End fu NancyNotesList
