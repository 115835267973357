
export const NANCYIMAGES = {
    NANCY_HERO: {
        alt: {
            short: "The Nancy App - you personal productivity assistant",
            long: ""
        },
        url: {
            //large:  "https://nancypublicmediablob01.blob.core.windows.net/nancy-app-public-ux-media-blob/nancy-hero-image-transparent-large.png",
            large: "https://nancypublicmediablob01.blob.core.windows.net/nancy-app-public-ux-media-blob/nancy-hero-image-transparent-large.jpg",
            //medium: "https://nancypublicmediablob01.blob.core.windows.net/nancy-app-public-ux-media-blob/nancy-hero-image-transparent-medium.png"
            medium: "https://nancypublicmediablob01.blob.core.windows.net/nancy-app-public-ux-media-blob/nancy-hero-image-transparent-medium.jpg"
        }
    }
}
