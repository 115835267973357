import React, { useContext } from "react";
import { Box } from "@mui/material";
import { AzureAuthContext } from "../azure-components/AzureUserInfo";
import { useNancyStatusContext, NANCY_STATUS } from "../nancy-contexts/NancyStatusContex";

/**
 *
 * Renders the footer component that will hold status information etc.
 * @returns {JSX.Element} The NancyFooter component.
 */
export default function NancyFooter() {
    const { userIsLoggedIn, accountInfo } = useContext(AzureAuthContext);
    const { currentNancyStatusMessage, currentNancyStatusType } = useNancyStatusContext();

    const getMessageStyle = (statusType) => {
        switch (statusType) {
            case NANCY_STATUS.WAITING:
                return { color: "#eee" };
            case NANCY_STATUS.SUCCESS:
                    return { color: "#49cc49" };
            case NANCY_STATUS.WARNING:
                return { color: "orange" };
            case NANCY_STATUS.ERROR:
                return { color: "red" };
            case NANCY_STATUS.INFO:
            default:
                return { color: "#809ed6" };
        }
    };
    // add ...getMessageStyle(currentNancyStatusType) to style={{ ...getMessageStyle(currentNancyStatusType) }} block to spread the styles inline.
    // or use .color to a parcirular definition, like: backgroundColor: getMessageStyle(currentNancyStatusType).color

    return (
        <footer className="NancyFooter" style={{}}>
            <Box sx={{ display: "flex" , alignItems: "center", /*justifyContent: "space-between"*/ }} style={{ border: '0px solid red' }}>
                <Box className="NancyFooterStatusText" sx={{ flexGrow:0, display: { xs: 'none', md: 'flex' } }} style={{ border: '0px solid purple' }}>
                    User: {userIsLoggedIn ? accountInfo.name : 'Guest'}
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} style={{ border: '0px dashed #aaa' }} /* Spacer */ />
                <Box className="NancyFooterStatusText" sx={{ flexGrow:0, display: { xs: 'none', md: 'flex' } }} style={{ margin: '0 1em 0 1em' }}>
                    {currentNancyStatusMessage}
                </Box>
                <Box
                    className="NancyFooterStatusSquare"
                    sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}
                    style={{
                        width: '0.8em',
                        height: '0.8em',
                        backgroundColor: getMessageStyle(currentNancyStatusType).color,
                        margin: '0',
                        border: '1px solid #607cb4'
                    }}
                />
            </Box>
        </footer>
    );
};
