import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material";
import { AzureAuthContext } from "../../../../components/azure-components/AzureUserInfo";
import { NancyNotesBackend_CreateNote } from "../../services/NancyNotesBackendInterface";
import NotesIcon from '@mui/icons-material/Notes';
import { useNancyNotesDataContext } from "../../contexts/NancyNotesContext";

export default function NancyNoteCreateNewNoteWidget() {
    
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [noteTitle, setNoteTitle] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const textfieldRef = useRef(null);
    const { accountInfo, userIsLoggedIn } = useContext(AzureAuthContext);
    const { NancyNotesAddNote } = useNancyNotesDataContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (isDialogOpen) {
            // When the dialog is opened, focus on the text field
            setTimeout(() => {
                textfieldRef.current?.focus();
            }, 100);
        }
    }, [isDialogOpen]);
  
    const handleOpenDialog = () => {
      setIsDialogOpen(true);
    };
  
    const handleCloseDialog = () => {
        textfieldRef.current.value = "";
        setIsDialogOpen(false);
        setIsLoading(false);
    };

    // On success this is what NancyNotesBackend_CreateNote returns:
    // {
    //     "Test": "Successful",
    //     "Response": {
    //       "created_at": "2023-05-19T16:12:04.194Z",
    //       "updated_at": "2023-05-19T16:12:04.194Z",
    //       "aad_user_email": "frode@amplio.co",
    //       "revision": 1,
    //       "previous_revision_id": null,
    //       "document_title": "Ugh",
    //       "document_path": "/",
    //       "document_tags": [],
    //       "document_content": "",
    //       "_id": "64679fd4085ea56e749844d5",
    //       "__v": 0
    //     }
    //   }
    const createNote = async ({aad_user_email, document_title = noteTitle, document_path = '/', document_tags = [], content_json = '{}', content_text = ''}) => {
        //console.log("createNote() called: ", {aad_user_email, document_title, document_path, document_tags, content_json, content_text})
        try {
            const result = await NancyNotesBackend_CreateNote({aad_user_email, document_title, document_path, document_tags, content_json, content_text});
            //console.log(JSON.stringify(result, null, 2)); // or do something else with the result
            //console.log("NEW DOCUMENT ID:", result.Response._id)
            if( result.Response._id ) {
                // Add the new note to the NancyNotesContext
                NancyNotesAddNote(result.Response);
                // Redirect to <Route path="/notes/note/id/:noteId" element={<NancyNote />} /> where noteId = result.Response._id
                navigate(`/notes/note/id/${result.Response._id}?edit=content`);
            }
        } catch (error) {
            // TODO: Do better error handling
            // PLAN:
            //  - Show the error in the footer status
            //  - Display an error dialog or take the user to an error page
            console.error(error); // or handle the error in another way
        }
    };

    const handleCreateNote = () => {
        setIsLoading(true);
        const params = {
            aad_user_email: accountInfo.email,
            document_title: noteTitle,
            document_path: "/",
            document_tags: [],
            content_json: {ops:[]},
            content_text: ""
        };
        createNote(params).finally(() => {
            textfieldRef.current.value = "";
            setIsDialogOpen(false);
            setIsLoading(false);
        });
    };
    

    const handleTitleChange = (event) => {
      setNoteTitle(event.target.value);
    };

    if( userIsLoggedIn ) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
                marginRight="1em"
            >
                <Button
                    variant="contained"
                    size="medium"
                    startIcon={<NotesIcon />}
                    sx={{
                        fontWeight: "bold",
                        backgroundColor: "#CFDEEC",
                        color: "#1D354A",
                        '&:hover': {
                            backgroundColor: "#F0F4F9",
                            color: "#0C3B7B"
                        },
                    }}
                    onClick={handleOpenDialog}
                >
                    New Note
                </Button>
                <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
                    <DialogTitle>Create New Note</DialogTitle>
                    <DialogContent>
                        <TextField
                            inputRef={textfieldRef}
                            disabled={isLoading}
                            label="Title"
                            value={noteTitle}
                            fullWidth
                            onChange={handleTitleChange}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleCreateNote();
                                }
                            }}
                        />
                    </DialogContent>
                    <DialogActions style={{display: 'flex', justifyContent: 'center'}}>
                        {isLoading ? (
                            <CircularProgress />
                        ) : (
                            <>
                                <Button onClick={handleCloseDialog}>Cancel</Button>
                                <Button onClick={handleCreateNote}>Create</Button>
                            </>
                        )}
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }
    else {
        return (<></>);
    }
} // End Functional Component <NancyNoteCreateNewNoteWidget>
