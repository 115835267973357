import React from 'react'
import { Typography } from '@mui/material';
import {NANCYIMAGES} from "../../configurations/NancyImageConstants";

export default function NancyAboutPage() {
    return (
        <>
            <img
                src={NANCYIMAGES.NANCY_HERO.url.medium}
                alt="Nancy"
                style={{
                    float: 'right',
                    maxWidth: "50%",
                    maxHeight: "95%",
                    marginLeft: '10px',
                    marginBottom: '10px'
                }}
            />
            <Typography variant="h2" component="h1" gutterBottom>About Nancy</Typography>
            <Typography paragraph>Nancy is currently being developed as a personalized productivity tool, designed to provide a system for personal note-taking, information research and knowledge management. It's a tool crafted with the notion of creating a unified space where users can streamline their personal productivity.</Typography>

            <Typography variant="h4" component="h2" gutterBottom>Core Functionality</Typography>
            <Typography paragraph>At its core, Nancy is centered around a simple note-taking module. This module permits users to employ a rich text editor. Notes can be organized using "paths" resembling a folder structure. Notes are then displayed as an expandable tree view of these paths. Complementing these features, Nancy includes a tagging system to further help organize notes.</Typography>

            <Typography variant="h4" component="h2" gutterBottom>Future Developments</Typography>
            <Typography paragraph>While the current focus of Nancy remains on creating an efficient note-taking environment, plans for future expansions are underway. These developments are aimed at introducing new modules to offer more comprehensive productivity solutions.</Typography>
            
            <Typography paragraph><strong>ChatBot:</strong> One significant addition will be the integration of a ChatGPT-enabled AI chatbot. This feature will use the OpenAI GPT API to manage chat histories, engineer prompts, and store results on the backend. This advancement aims to enhance Nancy's productivity capabilities by adding an intelligent assistant component.</Typography>
            <Typography paragraph><strong>Web Search:</strong> In the same spirit another module on the roadmap is a module that uses APIs to conduct web searches and present the result to the user, for further refinement and preservation. The goal is to use both the chatbot and the websearch modules to enable the user to do better research faster, and have it automatically documented.</Typography>
            <Typography paragraph><strong>Best Practices:</strong> Another future module will concentrate on security standards and best practices. This addition is intended to provide a central repository of security knowledge. Users will have a single source of truth for security standards, enabling them to create informed recommendations during customer projects.</Typography>
            <Typography paragraph><strong>Infosec Archive:</strong> Another future module will concentrate on published security reports and intelligence. The aim of this module will be to make keeping up with the latest development in the security industry and threat landscape, and summarize events and trends for easier consumption. It will also use the ChatBot interface to let you chat with your data, extracting potential answers to you questions across the entire knowledge base.</Typography>

            <Typography variant="h4" component="h2" gutterBottom>Potential Applications</Typography>
            <Typography paragraph>Nancy is currently a personalized productivity tool with a future prospect of evolving into a customer-facing product. This transformation will be driven by the development of various modules. With its rich feature set and a roadmap for future expansions, Nancy aims to bridge the gap between personal productivity tools and professional knowledge management systems.</Typography>
            <Typography paragraph>Please note that Nancy is in its early stages and continues to grow. This page will be updated as the tool evolves, reflecting the introduction of new modules and the progression of existing ones.</Typography>
        </>
    );
};
