// https://www.robinwieruch.de/react-router-authentication/
import React, { useContext } from 'react'
import {Typography, Box, Button } from "@mui/material";
import { AzureUserInfo, AzureAuthContext } from "../azure-components/AzureUserInfo";
import AzureLogoutButton from "../azure-components/AzureLogoutButton";

export default function NancyUserLogin() {
  const { instance, userIsLoggedIn, accountInfo } = useContext(AzureAuthContext);
  return (
    <div className="NancyUserProfile">
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} style={{ borderBottom: '2px solid black' }} >
            <Typography variant='h4'>Logout</Typography>
        </Box>
        {userIsLoggedIn ? (
        <Box style={{marginTop: '2em'}}>
            <AzureLogoutButton />
        </Box>
        ) : null}
        <Box style={{marginTop: '2em'}}>
            <AzureUserInfo />
        </Box>
    </div>
  );
};



