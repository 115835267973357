import React, { useContext } from "react";
import { Divider, Typography } from "@mui/material";
import { useLocation } from 'react-router-dom';
import '../../resources/styles/NancyDefaultTheme.css';
import { AzureAuthContext } from "../azure-components/AzureUserInfo";

import NancyListItemButton from '../custom-material-components/NancyListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import NotesIcon from '@mui/icons-material/Notes';
import NancyNotesList from '../../modules/NancyNotes/components/NancyNotesList/NancyNotesList';


/**
 *
 * Renders the sidebar component wher the content navigation (as opposed to app navigation) will reside.
 * @returns {JSX.Element} The NancySidebar component.
 */
export default function NancySidebar() {
    const { userIsLoggedIn } = useContext(AzureAuthContext);
    const location = useLocation();

    const doRenderNancyNotes = location.pathname.startsWith('/notes');

    // If user is logged in 
    if(!userIsLoggedIn){
        return (
            <aside className="NancySidebar">
                <p>Please log in to see the sidebar.</p>
            </aside>
        );
    }
    else if( doRenderNancyNotes && userIsLoggedIn)
        return (
            <aside className="NancySidebar">
                {userIsLoggedIn && doRenderNancyNotes && (
                    <NancyNotesList />
                )}
            </aside>
        );
    // Else, return a sidebar with the modules list:
    else {
        return (
            <aside className="NancySidebar">
                <Typography
                    variant="h5"
                    component="h3"
                    sx={{
                        padding: "15px 0 15px 10px",
                        fontWeight: "bold",
                        color: "#1D354A",
                    }}
                >
                    Modules
                </Typography>
                <Divider />
                <NancyListItemButton to={"/notes/"}>
                    <ListItemIcon
                        sx={{ margin: 0, marginRight: "-20px", padding: 0 }}
                    >
                        <NotesIcon
                            sx={{ margin: 0, padding: 0, color: "#aaa" }}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>Notes</Typography>
                        }
                        sx={{
                            margin: 0,
                            padding: 0,
                            "& .MuiListItemText-primary": {
                                margin: "0",
                                padding: "0",
                            },
                            "& .MuiListItemText-secondary": {
                                margin: "0",
                                padding: "0",
                            },
                        }}
                    />
                </NancyListItemButton>
            </aside>
        );
    }
};

