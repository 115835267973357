import React, { useRef, useState, useEffect } from "react";
import { useNancyStatusContext, NANCY_STATUS } from "../../../../components/nancy-contexts/NancyStatusContex";
import { useNancyNotesDataContext } from "../../contexts/NancyNotesContext";
import { NancyNotesBackend_UpdateNoteVirtualPath } from "../../services/NancyNotesBackendInterface";
import { Autocomplete, Box, Chip, Grid, TextField, Typography } from "@mui/material";

// OBSOLETE, TODO: Test and remove
// async function loadVirtualPathSuggestions() {
//     try {
//         const data = await NancyNotesBackend_ListUniquePaths();
//         //console.log("[loadVirtualPathSuggestions] Suggestions loaded:", JSON.stringify(data, null, 2));
//         return data;
//     } catch (error) {
//         console.error(error);
//     }
// }

/**
 * A recursive function to generate virtual path suggestions based on
 * the current virtual path and a given suggestions object.
 * 
 * @param {string[]} path - An array of strings representing the current virtual path.
 * @param {object} suggestions - An object containing the virtual path suggestions.
 * @return {string[]} - An array of strings containing the generated virtual path suggestions.
 */
function generateVirtualPathSuggestions(path, suggestions) {
    if (!path || !Array.isArray(path) || path.length === 0) {
        if (!suggestions) {
            return [];
        }
        return Object.keys(suggestions);
    }
    const [head, ...tail] = path;
    const nextSuggestions = suggestions[head];
    if (!nextSuggestions) {
        return [];
    }
    return generateVirtualPathSuggestions(tail, nextSuggestions);
} // End fu generateVirtualPathSuggestions


/**
 * A utility function to convert a virtual path string into an array of path elements.
 * @param {string} path_str - The virtual path string.
 * @return {string[]} - An array containing the individual path elements from the input string.
 */
function getVirtualPathArrayFromString(path_str) {
    // Split input string ("/path/to/document") and return an array (without empty elements)
    return path_str.split("/").filter((part) => part.trim() !== "");
} // End fu getVirtualPathArrayFromString

function getStringPathFromArray(virtual_path_array) {
    if( ! virtual_path_array || ! Array.isArray(virtual_path_array) ) { return "/"; }
    if( 0 === virtual_path_array.length ) { return "/"; }
    return "/" + virtual_path_array.join("/");
} // End fu getStringPathFromArray


/**
 * A React component that manages and displays the virtual path for a NancyNote,
 * including handling interactions such as updating the path.
 * 
 * @param {string} NancyNoteId - The unique ID of the NancyNote.
 * @param {string} NancyNotePathStr - The virtual path string of the NancyNote.
 */
export default function NancyNotePath({ NancyNoteId, NancyNotePathStr }) {

    const { NancyNotesPathSuggestions, NancyNotesUpdateNotePath } = useNancyNotesDataContext();

    // OBSOLETE now that we use NancyNotesPathSuggestions.
    // const [virtualPathSuggestions, setVirtualPathSuggestions] = useState({});
    // useEffect(() => {
    //     const fetchVirtualPathSuggestions = async () => {
    //         const suggestions = await loadVirtualPathSuggestions();
    //         setVirtualPathSuggestions(suggestions);
    //         //console.log("[NancyNotePath] Loaded suggestions: ", JSON.stringify(suggestions, null, 2))
    //     };
    //     fetchVirtualPathSuggestions();
    // }, []);
    
    // Use the global Nancy Status Context to update the status field in the footer when updating the title:
    const { updateTransientNancyStatusMessage } = useNancyStatusContext(); // updateGlobalNancyStatusMessage

    const pathAutoCompleteRef = useRef(null);
    const pathTextFieldRef = useRef(null);

    const [virtualPath, setVirtualPath] = useState([]);
    const [currentPathInput, setCurrentPathInput] = useState("");
    const [pathInputVisible, setPathInputVisible] = useState(false);
    const [pathAutocompleteKey, setpathAutocompleteKey] = useState(0);
    const [autocompleteOpen, setPathAutocompleteOpen] = useState(false);
    const [backspaceCount, setBackspaceCount] = useState(0);

    /**
     * A React effect hook that initializes the virtual path
     * state when the NancyNotePathStr prop changes.
     */
    useEffect(() => {
        if(NancyNotePathStr) {
            setVirtualPath(getVirtualPathArrayFromString(NancyNotePathStr));
        } // if note
    }, [NancyNotePathStr]);


    function getVirtualPathSuggestions() {
        if (NancyNotesPathSuggestions && virtualPath) {
            return generateVirtualPathSuggestions(virtualPath, NancyNotesPathSuggestions);
        } else {
            return [];
        }
    } // End fu getVirtualPathSuggestions

    /**
     * An async (onclick) event handler to handle the visibility of the path input
     * element and to store the updated path when the input is hidden.
     * 
     * @param {boolean} new_value - A boolean representing the desired visibility of the path input element.
     */
    const updatePathInputVisible = async (new_value) => {
        setPathInputVisible(new_value);
        if (true === new_value) {
            setTimeout(() => {
                pathTextFieldRef.current.focus();
                setPathAutocompleteOpen(true); // Open the autocomplete drop-down
            }, 100);
        } else {
            //console.log("[DEBUG:Path] updatePathInputVisible: Time to store the updated path.");
            //console.log("[DEBUG:Path] updatePathInputVisible: PATH:", virtualPath);
            updateTransientNancyStatusMessage("Saving path...", NANCY_STATUS.WAITING);
            NancyNotesUpdateNotePath(NancyNoteId, getStringPathFromArray(virtualPath));
            const res = await NancyNotesBackend_UpdateNoteVirtualPath(NancyNoteId, virtualPath);
            if( res ) {
                updateTransientNancyStatusMessage("Path saved", NANCY_STATUS.SUCCESS);
            }
            else {
                updateTransientNancyStatusMessage("Path unexpectedly NOT saved", NANCY_STATUS.ERROR);
            }
            //console.log("[DEBUG:Path] updatePathInputVisible: res:", res);
        }
    }; // End fu updatePathInputVisible

    /**
     * A event handler to handle various key events when interacting with the path input TextField.
     * 
     * @param {object} event - The event object representing the keyboard event.
     */
    const handlePathTextfieldKeyDown = (event) => {
        //console.log("[DEBUG:Path] handlePathTextfieldKeyDown called.");
        // Handle ENTER key press: Add current input as a virtual path fragment
        if (event.key === "Enter" && !pathAutoCompleteRef.current.popupOpen) {
            event.preventDefault();
            if (currentPathInput && currentPathInput.length > 0) {
                setVirtualPath([...virtualPath, currentPathInput]);
                setCurrentPathInput("");
                setpathAutocompleteKey((prevKey) => prevKey + 1);
                pathAutoCompleteRef.current.blur();
                setTimeout(() => {
                    pathTextFieldRef.current.focus();
                    setPathAutocompleteOpen(true); // Open the autocomplete drop-down
                }, 100);
            }
        }
        // Handle ESCAPE key press: Blur (and hence hide) the autocomplete Textfield element
        else if (event.key === "Escape") {
            pathTextFieldRef.current.blur();
        }
        // Handle Backspace in the else to be able to set backspace count properly to 0 in only one place
        else {
            if (event.key === "Backspace" && currentPathInput === "") {
                setBackspaceCount((prevCount) => prevCount + 1);
                if (backspaceCount >= 1) {
                    setVirtualPath((prevPath) => prevPath.slice(0, -1));
                    setBackspaceCount(0);
                }
            } else {
                setBackspaceCount(0);
            }
        }
    }; // End fu handlePathTextfieldKeyDown

    /**
     * An event handler to handle changes in the path input TextField.
     * 
     * @param {object} event - The event object representing the input change event.
     * @param {string} value - The current value of the path input TextField.
     */
    const handlePathInputChange = (event, value) => {
        //console.log("[DEBUG:Path] handlePathInputChange called.");
        if (value.endsWith("/")) {
            setVirtualPath([...virtualPath, value.slice(0, -1)]);
            setCurrentPathInput("");
        } else {
            setCurrentPathInput(value);
        }
    };

    /**
     * A function to handle the selection of a suggested virtual path from the Autocomplete component.
     * 
     * @param {object} event - The event object representing the selection event.
     * @param {string} newValue - The newly selected value from the Autocomplete component.
     * @param {string} reason - A string describing the reason for the selection event.
     */
    const handlePathSelection = (event, newValue, reason) => {
        //console.log("[DEBUG:Path] handlePathSelection called.");
        if (
            newValue &&
            newValue.length > 0 &&
            (reason === "selectOption" ||
                reason === "select-option" ||
                reason === "blur")
        ) {
            //console.log("[DEBUG:Path] handlePathSelection reason:", reason);
            //console.log("[DEBUG:Path] handlePathSelection value:", newValue);
            //console.log("[DEBUG:Path] handlePathSelection paht:", [
            //    ...virtualPath,
            //    newValue,
            //]);
            setVirtualPath([...virtualPath, newValue]);
            setCurrentPathInput("");
            setpathAutocompleteKey((prevKey) => prevKey + 1);
            pathAutoCompleteRef.current.blur();
            setTimeout(() => {
                pathTextFieldRef.current.focus();
                setPathAutocompleteOpen(true); // Open the autocomplete drop-down
            }, 100);
        }
    };

    // The JSX markup for rendering the NancyNotePath component,
    // including the virtual path display and interactive input elements.
    return (
        <Box
            sx={{
                mb: 0,
                border: "",
                "&:hover": {
                    backgroundColor: "#f4f8fc",
                },
            }}
            onClick={() => updatePathInputVisible(true)}
        >
            <Grid
                container
                direction="row"
                wrap="nowrap"
                alignItems="center"
                spacing={0}
                sx={{ mx: 0, my: 0 }}
            >
                <Grid item>
                    <Typography
                        variant="subtitle2"
                        component="span"
                        sx={{
                            fontSize: "0.9rem",
                            fontWeight: "bold",
                            mr: 0,
                            color: "#777",
                        }}
                    >
                        Path: /
                    </Typography>
                </Grid>
                {virtualPath.map((pathElement, index) => (
                    <React.Fragment key={pathElement}>
                        {index > 0 && virtualPath.length > 0 && (
                            <Grid item sx={{ mx: 0 }}>
                                <Typography
                                    sx={{
                                        mx: 0,
                                        fontWeight: "bold",
                                        color: "#777",
                                    }}
                                >
                                    /
                                </Typography>
                            </Grid>
                        )}
                        <Grid item sx={{ mx: 0, my: 0 }}>
                            <Chip
                                label={pathElement}
                                sx={{
                                    borderRadius: 0,
                                    fontSize: "0.9rem",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: "#777",
                                    mx: 0.2,
                                    my: 0,
                                    px: 0,
                                    py: 0,
                                    "& .MuiChip-label": {
                                        // Add this block to remove any padding inside the Chip's label
                                        padding: 0,
                                    },
                                }}
                            />
                        </Grid>
                    </React.Fragment>
                ))}
                <Grid item sx={{ mx: 0, my: 0 }}>
                    {virtualPath.length > 0 && (
                        <Typography
                            sx={{
                                mx: 0,
                                my: 0,
                                fontWeight: "bold",
                                color: "#777",
                            }}
                        >
                            /
                        </Typography>
                    )}
                </Grid>

                {pathInputVisible && (
                    <Grid item xs>
                        {
                            <Autocomplete
                                ref={pathAutoCompleteRef}
                                key={pathAutocompleteKey}
                                open={autocompleteOpen}
                                onOpen={() => setPathAutocompleteOpen(true)}
                                onClose={() => setPathAutocompleteOpen(false)}
                                freeSolo
                                options={ getVirtualPathSuggestions() }
                                onInputChange={handlePathInputChange}
                                onChange={handlePathSelection}
                                inputValue={currentPathInput}
                                sx={{
                                    width: "100%",
                                    "& .MuiAutocomplete-paper": {
                                        minWidth: "600px",
                                        maxHeight: "75px",
                                    },
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        inputRef={pathTextFieldRef}
                                        {...params}
                                        size="small"
                                        onKeyDown={handlePathTextfieldKeyDown}
                                        onBlur={() =>
                                            updatePathInputVisible(false)
                                        }
                                        InputProps={{
                                            ...params.InputProps
                                        }}
                                        sx={{
                                            padding: "4px",
                                            width: "100%",
                                            backgroundColor: "transparent",
                                        }}
                                    />
                                )}
                            />
                        }
                    </Grid>
                )}
            </Grid>
        </Box>
    ); // Return
}