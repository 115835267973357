import React, { useContext } from 'react';
import { Box, Divider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AzureAuthContext } from "../../../../components/azure-components/AzureUserInfo";
import NancyNotesListTree from './NancyNotesListTree';
import NotesIcon from '@mui/icons-material/Notes';

/**
 * NancyNotesList renders a list of notes for the sidebar navigation pane.
 * It retrieves the list of notes from the NancyNotesDataContext and maps
 * each note to a NancyListItemButton component. The component displays the
 * note title as the primary text and the note path as the secondary text.
 *
 * When the list of notes is not initialized or is not an array, the component
 * displays a loading placeholder.
 *
 * @returns {JSX.Element} Returns a list of links to notes.
 */
export default function NancyNotesList() {
    const { userIsLoggedIn } = useContext(AzureAuthContext);
    const navigator = useNavigate();
    
    if( ! userIsLoggedIn ) {
        return(
            <div id="NotesListContainer">
                <Typography variant='h5'>Warning: You are not logged in. Access denied.</Typography>
            </div>
        );
    }
    
    return (
        <div id="NotesListContainer">
            <Box 
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '15px 0 15px 10px',
                    '&:hover': {
                        cursor: 'pointer',
                    },
                }}
                onClick={() => {navigator("/notes")}}
            >
                <NotesIcon
                    sx={{ margin: 0, padding: 0, color: "#aaa", marginRight: '10px' }}
                />
                <Typography variant="h5" component="h3" sx={{
                    fontWeight: 'bold',
                    color: '#1D354A',
                }}>
                    Notes
                </Typography>
            </Box>
            <Divider sx={{marginBottom: 2}} />
            <NancyNotesListTree />
        </div>
    );
  } // End fu NancyNotesList
