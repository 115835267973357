import React from 'react'
import { Routes, Route, useLocation  } from 'react-router-dom';
import NancyDefaultPage from '../nancy-pages/NancyDefaultPage';
import NancyAboutPage from '../nancy-pages/NancyAboutPage';
import NancyNotFoundPage from '../nancy-pages/NancyNotFoundPage';
import NancyUserProfile from '../nancy-pages/NancyUserProfile';
import NancyUserSettings from '../nancy-pages/NancyUserSettings';
import ProtectedRoute from '../nancy-routers/NancyProtectedRoute';
import NancyUserLogin from '../nancy-pages/NancyUserLogin';
import NancyUserLogout from '../nancy-pages/NancyUserLogout';
import NancyTestAzureFunctions from '../nancy-pages/NancyTestAzureFunctions';
import { NancyNotesRoutes } from '../../modules/NancyNotes';
//import { NancyNote } from '../../modules/NancyNotes';
//import { NancyNotes } from '../../modules/NancyNotes';

/**
 *
 * Renders the content pane component that will be the area where the main content of the app will be displayes.
 * @returns {JSX.Element} The NancyContentPane component.
 */
export default function NancyContentPane() {
    
    const location = useLocation();
    React.useEffect(() => {
        // Force a re-render whenever the location changes
        // Fixes bug with 2/3rds of click in navpane not updating content pane.
        // Not optimal. A better fix or a re-write to omit the problem should be found.
    }, [location]);

    return (
        <main className='NancyContentPane' style={{
            minHeight: '100%',
            margin: 0,
            padding: '2em 5em 2em 5em',
            textAlign: 'left',
        }}>
            <Routes>
                <Route path="/" element={<NancyDefaultPage />} />
                <Route path="/user/login" element={<NancyUserLogin />} />
                <Route element={<ProtectedRoute />}>
                    <Route path="/about" element={<NancyAboutPage />} />
                    <Route path="/logout" element={<NancyUserLogout />} />
                    <Route path="/user/profile" element={<NancyUserProfile />} />
                    <Route path="/user/settings" element={<NancyUserSettings />} />
                    <Route path="/af/test" element={<NancyTestAzureFunctions />} />
                    <Route path="/notes/*" element={<NancyNotesRoutes />} />
                    <Route path="/*" element={<NancyNotFoundPage />} />
                </Route>
            </Routes>
        </main>
    );
};
