import React from 'react';
import { ListItemIcon, ListItemText, Typography, Tooltip } from "@mui/material";
import NancyListItemButton from '../../../../components/custom-material-components/NancyListItemButton';
import moment from 'moment';
import NotesIcon from '@mui/icons-material/Notes';

/**
 *
 * @returns {JSX.Element} Returns a list of links to notes.
 */

export default function NancyNotesListItem({ item }) {
    return (
            <Tooltip
                key={'tt_' + item.id}
                title={
                    <>
                        <Typography><strong>Title:</strong> {item.name}</Typography>
                        <Typography><strong>ID:</strong> {item.id}</Typography>
                        <Typography><strong>Path:</strong> {item.path}</Typography>
                        <Typography><strong>Author:</strong> {item.author}</Typography>
                        <Typography><strong>Created:</strong> {moment(item.created).format("YYYY-MM-DD HH:mm")}</Typography>
                        <Typography><strong>Updated:</strong> {moment(item.updated).format("YYYY-MM-DD HH:mm")}</Typography>
                    </>
                }
                placement={"right"}
            >
                <div
                    draggable
                    onDragStart={(e) => {
                        //console.log("dragging", item.id)
                        const data = { id: item.id, path: item.path, name: item.name, type: item.type };
                        e.dataTransfer.setData('application/my-app', JSON.stringify(data));
                        //e.dataTransfer.setData('application/my-app', item.id);
                        e.dataTransfer.dropEffect = 'move';
                    }}
                >
                    <NancyListItemButton
                        key={item.id}
                        to={`/notes/note/id/${item.id}`}
                        // divider
                    >
                        <ListItemIcon sx={{ margin: 0, marginRight: '-20px', padding: 0 }}>
                            <NotesIcon sx={{ margin: 0, padding: 0, color: '#aaa' }} />
                        </ListItemIcon>
                        <ListItemText
                            primary={item.name}
                            sx={{
                            margin: 0,
                            padding: 0,
                            "& .MuiListItemText-primary": {
                                margin: "0",
                                padding: "0",
                            },
                            "& .MuiListItemText-secondary": {
                                margin: "0",
                                padding: "0",
                            },
                            }}
                        />
                    </NancyListItemButton>
                </div>
            </Tooltip>
    ); // End return statement
} // End component NancyNotesListFolder

  