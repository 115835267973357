// https://www.robinwieruch.de/react-router-authentication/
import React from 'react'
import {Typography, Box, Button } from "@mui/material";
import AzureLoginButton from "../azure-components/AzureLoginButton";
import { AzureUserInfo } from "../azure-components/AzureUserInfo";

export default function NancyUserLogin() {
    return (
    <div className="NancyUserProfile">
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} style={{ borderBottom: '2px solid black' }} >
            <Typography variant='h4'>Login</Typography>
        </Box>
        <Box style={{marginTop: '2em'}}>
            <AzureLoginButton />
        </Box>
        <Box style={{marginTop: '2em'}}>
            <AzureUserInfo />
        </Box>
    </div>
  );
};



