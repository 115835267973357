import { useMsal } from "@azure/msal-react";
import { NancyMSALLoginRequest } from "../../configurations/NancyMSALAuthConfig";

export default function AzureLoginButton() {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginPopup(NancyMSALLoginRequest).catch((error) => {
            console.error(error);
        });
    };

    return <button onClick={handleLogin}>Azure AD Login</button>;
};
