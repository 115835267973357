import React, { createContext, useEffect, useState, useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";


/**
 * This context is used to provide information about the current user's
 * authentication status and account information to other components.
 */
export const AzureAuthContext = createContext({
    userIsLoggedIn: false,
    accountInfo: {
        name: "Guest",
        email: "",
    },
    instance: null,
});

/**
 * This component is used to provide information about the current user's
 * authentication status and account information to other components.
 * 
 * @param {*} param0 
 * @returns 
 */
export function AzureUserInfo({ children }) {
    const { instance, accounts } = useMsal();
    const account = accounts[0] || null;
    const [ userIsLoggedIn, setUserIsLoggedIn ] = useState(false);

    const [accountInfo, setAccountInfo] = useState({
        name: account ? account.name : "Guest",
        email: account ? account.username : "",
    });

    // This effect is used to update the state of the
    // userIsLoggedIn variable and the accountInfo variable
    // whenever the account variable changes.
    useEffect(() => {
        if (account) {
            setUserIsLoggedIn(true);
            setAccountInfo({
                name: account.name,
                email: account.username,
            });
        } else {
            setUserIsLoggedIn(false);
            setAccountInfo({
                name: "Guest",
                email: "",
            });
        }
    }, [account]);

    // Adding an observer on the MSAL instance to watch for changes in the accounts array.
    // When a change occurs (i.e., a user logs out), we can use the setAccountInfo method
    // from the context to update the state of your application.
    // https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-initializing-client-applications#add-an-observer-to-handle-changes-in-the-account-array
    // We do this to try to fix a bug where the interface (which implies the userIsLoggedIn variable)
    // is not updated when the session with Azure expires.
    useEffect(() => {
        const callbackId = instance.addEventCallback((message) => {
            if (message.eventType === EventType.LOGIN_SUCCESS || message.eventType === EventType.LOGOUT_SUCCESS) {
                const currentAccounts = instance.getAllAccounts();
                const currentAccount = currentAccounts[0] || null;
                setAccountInfo({
                    name: currentAccount ? currentAccount.name : "Guest",
                    email: currentAccount ? currentAccount.username : "",
                });
            }
        });
        return () => {
            instance.removeEventCallback(callbackId);
        }
    }, [instance]);

    return (
        <AzureAuthContext.Provider value={{ instance, userIsLoggedIn, accountInfo, account }} >
            {children}
        </AzureAuthContext.Provider>
    );
}; // End functional component <AzureUserInfo>


/**
 * This component is used to display the current user's
 * authentication status and account information.
  * 
 * @returns 
 */
export function AzureUserInfoBox() {
    const { userIsLoggedIn, accountInfo } = useContext(AzureAuthContext);
    //console.log("AzureUserInfoBox being called.")
  
    if (!userIsLoggedIn) {
      return (
        <p>You are currently logged out.</p>
      );
    }
  
    return (
      <div>
        <h3>Currently logged in Azure user</h3>
        <p>Name: {accountInfo.name}</p>
        <p>Email: {accountInfo.email}</p>
      </div>
    );
} // End functional component <AzureUserInfoBox>
