import React, { useState, useEffect } from "react";
import { TextField, Typography } from "@mui/material";
import { useNancyStatusContext, NANCY_STATUS } from "../../../../components/nancy-contexts/NancyStatusContex";
import { useNancyNotesDataContext } from "../../contexts/NancyNotesContext";
import { NancyNotesBackend_UpdateNoteTitle } from "../../services/NancyNotesBackendInterface";

/**
 * NancyNoteTitle is a React functional component responsible for rendering and
 * managing the note title. It handles switching between view and edit modes,
 * and updates the title in the backend when changes are made.
 *
 * @component
 * @param {string} NancyNoteId - The ID of the note for which the title is being displayed.
 * @param {string} NancyNoteTitle - The title of the note to be displayed.
 */
export default function NancyNoteTitle({ NancyNoteId, NancyNoteTitle }) {
    
    // Use the global Nancy Status Context to update the status field in the footer when updating the title:
    const { updateTransientNancyStatusMessage } = useNancyStatusContext(); // updateGlobalNancyStatusMessage

    const { NancyNotesUpdateNoteTitle } = useNancyNotesDataContext();

    // State for the note title and edit mode of the title element:
    const [noteTitle, setNoteTitle] = useState("Loading title...");
    const [noteTitleEditModeEnabled, setNoteTitleEditModeEnabled] = useState(false);

    // Hook to trigger a local update of the title whenever
    // the parent component updates the NancyNoteTitle property:
    useEffect(() => {
        setNoteTitle(NancyNoteTitle);
    }, [NancyNoteTitle]);

    // 
    const handleNoteTitleDoubleClick = () => {
        setNoteTitleEditModeEnabled(true);
    };

    /**
     * handleNoteTitleBlur is an async event handler for the title TextField blur event.
     * It saves the new note title to the backend and updates the local state.
     *
     * @async
     * @function
     * @param {Object} e - The event object from the TextField onBlur event.
     */
    const handleNoteTitleBlur = async (e) => {
        const newTitle = e.target.value;
        newTitle.trim();
        if (!newTitle) {
            return false;
        }
        setNoteTitle(newTitle);

        // Call the save function to update the backend
        try {
            //console.log(`[handleNoteTitleBlur] Updating title for note id ${NancyNoteId}: ${newTitle}`);
            updateTransientNancyStatusMessage("Saving new title...", NANCY_STATUS.WAITING);
            await NancyNotesBackend_UpdateNoteTitle(NancyNoteId, newTitle);
            NancyNotesUpdateNoteTitle(NancyNoteId, newTitle);
            updateTransientNancyStatusMessage("New title saved.", NANCY_STATUS.SUCCESS);
        } catch (error) {
            console.error("[handleNoteTitleBlur] Error saving title:", error);
            updateTransientNancyStatusMessage(
                "WARNING: Error saving new title.",
                15000
            );
        }

        setNoteTitleEditModeEnabled(false);
    };
    const handleTitleListKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.target.blur();
        }
    }; // End event handler handleTagListKeyDown

    /**
     * handleNoteTitleBlur is an async event handler for the title TextField blur event.
     * It saves the new note title to the backend and updates the local state.
     *
     * @async
     * @function
     * @param {Object} e - The event object from the TextField onBlur event.
     */
    return (
        <>
            {noteTitleEditModeEnabled ? (
                <TextField
                    value={noteTitle}
                    onChange={(e) => setNoteTitle(e.target.value)}
                    onBlur={handleNoteTitleBlur}
                    onKeyDown={handleTitleListKeyDown}
                    autoFocus
                    fullWidth
                    variant="outlined"
                    sx={{ marginBottom: 0 }}
                />
            ) : (
                <Typography
                    variant="h4"
                    sx={{ marginBottom: 0, cursor: "pointer" }}
                    onDoubleClick={handleNoteTitleDoubleClick}
                >
                    {noteTitle}
                </Typography>
            )}
        </>
    );
} // End fu NancyNoteTitle
