import React from 'react'
import { Box, Typography } from "@mui/material";
import { AzureUserInfoBox } from "../azure-components/AzureUserInfo";

export default function NancyUserProfile() {
  return (
    <div className="NancyUserProfile">
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} style={{ borderBottom: '2px solid black' }} >
            <Typography variant='h4'>Profile</Typography>
        </Box>
        <Box>
          <AzureUserInfoBox />
        </Box>
    </div>
  );
};
