const FRONTEND_APP_CLIENT_ID = "c2085a11-6929-46d0-a8d8-5c7be17a9fef";
const TENANT_ID = "632a5f39-6ef1-456f-9f77-10fc367bae37";
let FRONTEND_APP_REDIRECT_URI;
const BACKEND_APP_CLIENT_ID = "ca4c8205-7775-4c59-9f92-613db34256e3"; //"ca4c8205-7775-4c59-9f92-613db34256e3";
const BACKEND_APP_SCOPE = "access_as_user";
//  const BACKEND_API_ID = "api://ca4c8205-7775-4c59-9f92-613db34256e3"

const BACKEND_API_ENDPOINT = 'https://amplioconancyappfunctionsapi.azurewebsites.net/api/';

// Determine redirect URL based on environment. We have three possible environments:
// Dev:
if (window.location.hostname === 'localhost') {
    FRONTEND_APP_REDIRECT_URI = 'http://localhost:3000';
}
// Azure prod
else if (window.location.hostname === 'calm-island-0734c6303.3.azurestaticapps.net') {
    FRONTEND_APP_REDIRECT_URI = 'https://calm-island-0734c6303.3.azurestaticapps.net/';
}
// Azure custom domain
else {
    FRONTEND_APP_REDIRECT_URI = 'https://nancy.app';
}


export const NancyMSALAuthConfig = {
  auth: {
    clientId: FRONTEND_APP_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${TENANT_ID}`,
    redirectUri: FRONTEND_APP_REDIRECT_URI,
  },
};  


export const AzureFuncAPIRequest = {
  url: BACKEND_API_ENDPOINT,
  scopes: [`api://${BACKEND_APP_CLIENT_ID}/${BACKEND_APP_SCOPE}`]
};

export const NancyMSALLoginRequest = {
  scopes: [
    "openid",
    "profile",
    //"email", // don't need this for now
    `api://${BACKEND_APP_CLIENT_ID}/${BACKEND_APP_SCOPE}`,
  ],
};
