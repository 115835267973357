import React, {useContext} from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { Container, Typography, Box, Button, Link } from '@mui/material';
import { AzureAuthContext } from "../azure-components/AzureUserInfo";
import {NANCYIMAGES} from "../../configurations/NancyImageConstants";

export default function NancyHeroSplash() {
    const { instance, userIsLoggedIn } = useContext(AzureAuthContext);
    const navigator = useNavigate();
    const theme = useTheme();

    const handleAzureLogin = () => {
        instance.loginPopup().catch((error) => {
            console.error(error);
        });
    };


    return (
        <Box
        sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'column', md:'row', lg:'row', lg:'row' }, // column on xs, row on sm and above
            textAlign: { xs: 'center', sm: 'center', md:'left', lg:'left', lg:'left' }, // center text on xs, left align on sm and above
            alignItems: 'center',
            justifyContent: 'center', 
            height: userIsLoggedIn ? 'calc(100vh - 125px)' : '100vh',
          }}
        >
            <img
                src={NANCYIMAGES.NANCY_HERO.url.large}
                alt="Hero Image"
                style={{
                    float: "right",
                    height: { xs: '50%', sm: '50%', md: '', lg: '', xl: '' },
                    width: { xs: '100%', sm: '100%', md: '50%', lg: '40%', xl: '30%' },
                    maxWidth: { xs: '100%', sm: '50%' },
                    maxWidth: "50%",
                    maxHeight: "95%",
                    margin: "0 0 1em 1em",
                }}
            />
            <Container>
                <Typography variant="h2" component="h1" gutterBottom>
                    Welcome to Nancy
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                    The personal productivity app.
                </Typography>
                {userIsLoggedIn ?  (
                    <Typography variant='paragraph' component='p' sx={{mt: 3}}>
                        <Button variant="contained" color="primary" onClick={() => { navigator('/about')}}>
                            Learn More
                        </Button>
                    </Typography>
                ) : (
                    <Typography variant='paragraph' component='p' sx={{mt: 3}}>
                        If you have a user account, please <Link href="#" onClick={handleAzureLogin}>log in</Link>.
                    </Typography>
                )}
            </Container>
        </Box>
    );
} // End funcional component NancyHeroSplash
