import React, { useContext, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { AzureAuthContext } from "../../../../components/azure-components/AzureUserInfo";
import NancyNoteTitle from "./NancyNoteTitle";
import NancyNotePath from "./NancyNotePath";
import NancyNoteTags from "./NancyNoteTags";
import NancyNoteMetaInfo from "./NancyNoteMetaInfo";
import NancyNoteContent from "./NancyNoteContent";
import { NancyNotesBackend_GetNoteById } from "../../services/NancyNotesBackendInterface";
import { Box, Container, Divider, Typography } from "@mui/material";
import { useNancyStatusContext, NANCY_STATUS } from "../../../../components/nancy-contexts/NancyStatusContex";
import { useNancyNotesDataContext } from "../../contexts/NancyNotesContext";

/**
 * NancyNote is a React functional component responsible for rendering a
 * complete note page. It handles the loading of note data from the
 * NancyNotesAzureAPI and passes the data down to its child components.
 *
 * @component
 */
export default function NancyNote() {
    const { userIsLoggedIn } = useContext(AzureAuthContext);
    const { updateTransientNancyStatusMessage } = useNancyStatusContext(); //
    const { NancyNoteGetMetaById } = useNancyNotesDataContext();

    // Get the noteId from the url:
    let { noteId } = useParams();
    // Explanation: Defined by <Route path="/notes/note/id/:noteId" element={<NancyNote />} /> in NancyContentPane.js.
    // Validate MongoDB ObjectID
    const validObjectId = /^[0-9a-fA-F]{12,48}$/; // Valid is 24, but allow for future expansion.
    if (!validObjectId.test(noteId)) {
        //throw new Error('Invalid noteId'); // TODO handle errors better
        noteId = null;
    }

    const location = useLocation();

    // State variable for the note the page will display:
    const [note, setNote] = useState(null);

    // State variable used to prevent 
    const [fetchedNoteId, setFetchedNoteId] = useState(null);

    // Property to propagate the note title to the <NancyNoteTitle /> component:
    const [noteProps, setNoteProps] = useState({
        id: "",
        title: "Loading...",
        path: "",
        tags: [],
        meta: {},
        content: ""
    });

    /**
     * loadNoteDataById fetches note data by ID from the NancyNotesAzureAPI.
     * It checks if the data has already been fetched to prevent multiple calls
     * to the API for the same note.
     * 
     * NB: Running this outside the useEffect callback (fetchNote) code is necessary
     * to get the loading to work. I have tried many things. Only this works.
     * I do not exactly know why, but a lot of trial and error has
     * proven that this is the only way of many that actually works.
     * 
     * @function
     * @param {string} noteId - The ID of the note to fetch.
     * @returns {Object|null} The note data, or null if the data has already been fetched.
     */
    const loadNoteDataById = (noteId, abortSignal) => {
        if( !noteId ) { return null }
        const nextNoteMeta = NancyNoteGetMetaById(noteId);
        // If we load a note that has its title and path cached, show that while loading:
        if( nextNoteMeta && nextNoteMeta.title && nextNoteMeta.path ) {
            setNoteProps({
                id: noteId,
                title: nextNoteMeta.title,
                path: nextNoteMeta.path,
                tags: [],
                meta: {
                    author: "",
                    created: "",
                    updated: "",
                },
                content: "Loading...",
            });
        }
        // Else, reset the entire note:
        else {
            setNote(null);
        }
        const data = NancyNotesBackend_GetNoteById(noteId, abortSignal);
        return data;
    }; // ENd fu loadNoteDataById


    /**
     * This useEffect hook is triggered when the noteId prop changes. It fetches
     * the note data using loadNoteDataById and sets the note state.
     *
     * Note: In development mode with strict mode enabled, this hook may
     * trigger twice.
     *
     * @function
     * @listens {noteId} - The noteId state variable.
     */
    useEffect(() => {
        //console.log("[DEBUG::useEffect.noteId] noteId state updated.");
        const abortController = new AbortController();
        const abortSignal = abortController.signal;

        async function fetchNote() {
            if (noteId && fetchedNoteId !== noteId) {
                try {
                    const data = await loadNoteDataById(noteId, abortSignal);
                    if (data) {
                        //console.log("[DEBUG::useEffect.noteId] Called NancyNotesBackend_GetNoteById and got:", data);
                        setNote(data);
                        setFetchedNoteId(noteId);
                    }
                } catch (error) {
                    console.error("Error:", error);
                    updateTransientNancyStatusMessage("Error loading note data.", NANCY_STATUS.ERROR);
                    // TODO: Handle error better.
                }
            }
        }
        fetchNote();
        // Return a clean-up function that is run before any re-run of the hook:
        return () => {
            abortController.abort();
        };
        // NB: endless loop bug below may actually be because we load data in a useEffect hook, which is not recommended.
        // TODO: See if there is a better way, where the loading is not done in a useEffect hook.
        // (Adding loadNoteDataById actually leads to an endless loop.)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [noteId, location]);

    /**
     * This useEffect hook is triggered when the note state changes. It updates
     * the noteProps state with the loaded note data, making it available
     * to the child components.
     *
     * @function
     * @listens {note} - The note state variable.
     */
    useEffect(() => {
        if (noteId && note) {
            //console.log("[NancyNote::useEffect.note::DEBUG] noteId and note:", noteId, note)
            //console.log("CONTENT JSON:", note.content_json);
            setNoteProps({
                // Set the note id for all child components:
                id: noteId,
                // Set the title to propagate to <NancyNoteTitle>:
                title: note.title,
                // Set the path to propagate to <NancyNotePath>:
                path: note.path,
                // Set the tag to propagate to <NancyNoteTags>:
                tags: note.tags,
                // Set the meta info to propagate to <NancyNoteMetaInfo>:
                meta: {
                    author: note.aad_user_email,
                    created: new Date(note.created_at).toLocaleString(),
                    updated: new Date(note.updated_at).toLocaleString(),
                },
                // Set the content to propagate to <NancyNoteContent>:
                content_json: note.content_json,
                content_text: note.content_text
            });
        }
        // (we do not need the noteId here)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [note]);
    

    // Require that the user is logged in to view this page.
    // This is also handled elsewhere, but better safe than sorry.
    if( ! userIsLoggedIn ) {
        updateTransientNancyStatusMessage("Access Denied", NANCY_STATUS.WARNING)
        return <Typography>You must be logged in to view this page.</Typography>
    }

    // Until the note data is loaded and ready, render a holding message:
    if (!note) {
        return <Typography>Please wait while the page is loading...</Typography>;
    }

    // When the note data is loaded and ready render the note page.
    // Each of the child components displays and lets the user edit specific parts of the page:
    // - NancyNoteTitle: The page title (like "Meeting notes").
    // - NancyNotePath: The "virtual path" of the document (like "/path/to/doc").
    // - NancyNoteContent: The content of the document, editable in a rich text editor (Quill).
    // - NancyNoteMetaInfo: Meta info about the page, with author and created and updated dates.
    // - NancyNoteTags: A list of tags for the note.
    return (
        <Container maxWidth="lg" className="ContentPaneContainer NancyNoteContainer">
            <NancyNoteTitle NancyNoteId={noteProps.id} NancyNoteTitle={noteProps.title} />
            <Divider sx={{ mt: 0, mb: 0 }} />
            <NancyNotePath NancyNoteId={noteProps.id} NancyNotePathStr={noteProps.path} />
            <Divider sx={{ mt: 0, mb: 0 }} />
            <NancyNoteContent NancyNoteId={noteProps.id} NancyNoteContent={noteProps.content_json} />
            <Divider sx={{}} />
            <Box>
                <NancyNoteMetaInfo NancyNoteId={noteProps.id} NancyNoteMetaInfo={noteProps.meta} />
                <NancyNoteTags NancyNoteId={noteProps.id} NancyNoteTagsArray={noteProps.tags} />
            </Box>
        </Container>
    );
} // End Functional Component <NancyNote>
