/**
 * Inspiration and documentation:
 * https://www.smcculloch.com/how-to-call-aad-protected-azure-function-from-react
 * https://medium.com/@sumindaniro/azure-function-app-as-the-backend-api-for-webapps-reactjs-c6a6ce26246
 * https://learn.microsoft.com/en-us/azure/azure-signalr/signalr-tutorial-authenticate-azure-functions
 * https://stackoverflow.com/questions/64098147/calling-an-azure-ad-secured-azure-function-from-react-spa-azure-static-web-app
 * 
 */
import React, { useEffect, useState } from "react";
//import { AzureAPICall, AzureBearerToken } from '../azure-api-calls/AzureAPITest';
import  { AzureBearerToken, AzureBaseAPICall } from '../../services/AzureFunctionAPICallBase';
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
//import { useMsal } from "@azure/msal-react";
import { NancyNotesBackend_CreateNote, NancyNotesBackend_ListNotes, NancyNotesBackend_SearchNotes } from '../../modules/NancyNotes/services/NancyNotesBackendInterface';
import NancyNotesList from '../../modules/NancyNotes/components/NancyNotesList/NancyNotesList';
import { Box, Grid, FormControl, FormLabel, Typography, Button, TextField, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useNancyNotesDataContext } from "../../modules/NancyNotes/contexts/NancyNotesContext"; // NANCY_STATUS
import NancyNavLink from "../custom-material-components/NancyNavLink";


/**
 * 
 * @param {*} queryParams 
 * @returns 
 */
export async function NancyAzureAPITest1(queryParams = {}) {
    let endpoint = 'HTTestFunction';
    let method = 'GET';
    return AzureBaseAPICall({
        endpoint: endpoint,
        queryParams: queryParams,
        method: method,
        body: null
    });
} // End fu AzureAPICall

export default function NancyTestAzureFunctions() {
    const [accessToken, setAccessToken] = useState(null)
    //const { instance, accounts } = useMsal();

    const { NancyNotesTree, NancyNotes, NancyNotesPathSuggestions } = useNancyNotesDataContext();
    const [titleSearchTerm, setTitleSearchTerm] = useState("");
    const [pathSearchTerm, setPathSearchTerm] = useState("");
    const [tagSearchTerm, setTagSearchTerm] = useState("");
    const [notesListRaw, setNotesListRaw] = useState([]);
    const [notesList, setNotesList] = useState([]);

    const getAccessToken = async () => {
      const token = await AzureBearerToken()
      setAccessToken(token)
    }
  
    const [testMessage, setTestMessage] = useState('');
    const callTestAPIFunction = async () => {
        setTestMessage('Loading...');
        NancyAzureAPITest1({name: "Arne"})
        .then(data => { setTestMessage(data.message); console.log(data); })
        .catch(error => { console.error('Error:', error); setTestMessage('ERROR: Error calling API.')});
    };
    const [notesMessage, setNotesMessage] = useState('');
    const callCreateNoteAPIFunction = async () => {
        setNotesMessage('Loading...');
        NancyNotesBackend_CreateNote({})
        .then(data => { setNotesMessage(data); console.log(data); })
        .catch(error => { console.error('Error:', error); setNotesMessage('ERROR: Error calling API.')});
    };



    useEffect(() => {
        if(notesList) {
            //console.log("[useEffect] notesList: ", JSON.stringify(notesList, null, 2));
        }
    }, [notesList]);

    const handleNoteSearchButtonClick = (e) => {
        e.preventDefault();
        callListNotesAPIFunction();
    };

    const callListNotesAPIFunction = async () => {
        setNotesListRaw('Loading...');
        try {
            const data = await NancyNotesBackend_SearchNotes({ titleSearchTerm, pathSearchTerm, tagSearchTerm });
            setNotesListRaw(data.result.documents);
            setNotesList(data.result.documents);
            //console.log("[callListNotesAPIFunction] ", data);
        } catch (error) {
            console.error('Error:', error);
            setNotesMessage('ERROR: Error calling list notes API.');
        }
    };

    return(
        <div>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div>
                    Hello, I am authenticated.
                </div>
                <hr />
                <div>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={getAccessToken}
                    >
                        Get Access Token
                    </button>
                    {accessToken && <div>Access token is: {accessToken}</div>}
                </div>
                <hr />
                <div>
                    <button type="button" className="btn btn-primary" onClick={callTestAPIFunction}>
                    Call test API function
                    </button>
                    <div>{testMessage}</div>
                </div>
                <hr />
                <div>
                    <button type="button" className="btn btn-primary" onClick={callCreateNoteAPIFunction}>
                        Call Create Note API function
                    </button>
                    <pre>{JSON.stringify(notesMessage, null, 2)}</pre>
                </div>
                <hr />
                <Paper sx={{ border: '1px solid #ccc', padding: 2, backgroundColor: '#fafafa' }}>
                    <FormControl variant="outlined" fullWidth>
                        <FormLabel sx={{ fontSize: '1.5em', padding: '0 0 0.5em 0', margin: 0 }}>Search form</FormLabel>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="Title"
                                    value={titleSearchTerm}
                                    fullWidth
                                    sx={{backgroundColor: '#fff'}}
                                    onChange={(e) => setTitleSearchTerm(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="Path"
                                    value={pathSearchTerm}
                                    fullWidth
                                    sx={{backgroundColor: '#fff'}}
                                    onChange={(e) => setPathSearchTerm(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="Tags"
                                    value={tagSearchTerm}
                                    fullWidth
                                    sx={{backgroundColor: '#fff'}}
                                    onChange={(e) => setTagSearchTerm(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ height: '0.5em' }}/>
                        <Button variant="contained" color="primary" onClick={handleNoteSearchButtonClick}>
                            Search for notes
                        </Button>
                    </FormControl>
                    <Typography variant="h4" sx={{mt: 3}}>Notes list</Typography>
                    <Box sx={{  }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead sx={{fontSize: '40'}}>
                                    <TableRow>
                                        <TableCell align="left">
                                            <Typography variant="h6" component="div" fontWeight="bold">Title</Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant="h6" component="div" fontWeight="bold">Created At</Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant="h6" component="div" fontWeight="bold">Updated At</Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant="h6" component="div" fontWeight="bold">User Email</Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant="h6" component="div" fontWeight="bold">Path</Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant="h6" component="div" fontWeight="bold">Tags</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {notesList.map((note) => (
                                        <TableRow
                                            key={note._id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="left">
                                                <NancyNavLink to={`/notes/note/id/${note._id}`} >
                                                    {note.title}
                                                </NancyNavLink> 
                                            </TableCell>
                                            <TableCell align="left">{new Date(note.created_at).toLocaleString()}</TableCell>
                                            <TableCell align="left">{new Date(note.updated_at).toLocaleString()}</TableCell>
                                            <TableCell align="left">{note.aad_user_email}</TableCell>
                                            <TableCell align="left">{note.path}</TableCell>
                                            <TableCell align="left">{note.tags.join(', ')}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Paper>
                    <Paper sx={{ border: '1px solid #ccc', padding: 2, marginTop: 1, backgroundColor: '#fafafa' }}>
                        <Typography variant="h6">List of documents</Typography>
                        <NancyNotesList notes={notesList} />
                    </Paper>
                <Paper sx={{ border: '1px solid #ccc', padding: 2, marginTop: 1, backgroundColor: '#fafafa' }}>
                    <pre>{JSON.stringify(notesListRaw, null, 2)}</pre>
                </Paper>

                <Typography variant="h4" sx={{mt: 3}}>Path suggestions</Typography>
                <Paper sx={{ border: '1px solid #ccc', padding: 2, marginTop: 1, backgroundColor: '#fafafa' }}>
                    <pre>{JSON.stringify(NancyNotesPathSuggestions, null, 4)}</pre>
                </Paper>

                <Typography variant="h4" sx={{mt: 3}}>Note tree</Typography>
                <Paper sx={{ border: '1px solid #ccc', padding: 2, marginTop: 1, backgroundColor: '#fafafa' }}>
                    <pre>{JSON.stringify(NancyNotesTree, null, 4)}</pre>
                </Paper>

                <Typography variant="h4" sx={{mt: 3}}>Notes list</Typography>
                <Paper sx={{ border: '1px solid #ccc', padding: 2, marginTop: 1, backgroundColor: '#fafafa' }}>
                    <pre>{JSON.stringify(NancyNotes, null, 4)}</pre>
                </Paper>
            </MsalAuthenticationTemplate>
        </div>
    );
}


