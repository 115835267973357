import React, { useContext } from "react";
import { AzureAuthContext } from "../../../../components/azure-components/AzureUserInfo";
import { Box,  Container, Typography } from "@mui/material";

export default function NancyNotes() {
    const { userIsLoggedIn } = useContext(AzureAuthContext);

    // Check if the user is logged in. If not, display a message:
    if( ! userIsLoggedIn ) {
        return(
            <Typography>You are not logged in. Access denied.</Typography>
        );
    }
    // If the user is logged in, return the NancyNotes content:
    return (
        <Container maxWidth="lg" className="ContentPaneContainer">
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex'}, borderBottom: '2px solid black' }}  >
                <Typography variant='h4'>Notes</Typography>
            </Box>
            <Box sx={{ marginTop: '1em' }}>
                <Typography variant="paragraph">
                    Welcome to Nancy Notes.
                </Typography>
                <Typography variant="h5" sx={{mt: '1em'}}>Latest edits</Typography>
                <Typography variant="paragraph">
                    To be implemented.
                </Typography>
                <Typography variant="h5" sx={{mt: '1em'}}>Latest tags</Typography>
                <Typography variant="paragraph">
                    To be implemented.
                </Typography>
                <Typography variant="h5" sx={{mt: '1em'}}>Search notes</Typography>
                <Typography variant="paragraph">
                    To be implemented.
                </Typography>
            </Box>
        </Container>
    ); // return
} // End fu NancyNotes
