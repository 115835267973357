/* All React calls to the Azure Function backend related to the Notes feature of Nancy.  */
import  { AzureBaseAPICall } from '../../../services/AzureFunctionAPICallBase';

/**
 * Most calls have signal (abortSignal) from AbortController as a parameter
 * to be able to cancel calls to fetch and avoid race conditions if a link
 * is clicked or action is triggered before the previous one completed.
 */


/**
 * 
 * @param {*} queryParams 
 * @returns 
 */
export async function NancyNotesBackend_GetNoteById(note_id=null, abortSignal=null) {
    //console.log("[NancyNotesBackend_GetNoteById] Called with note_id:", note_id);
    let endpoint = 'HTNancyGetNoteById';
    let method = 'GET';
    if( ! note_id ) { throw new Error("[NancyNotesBackend_GetNoteById] No note_id given."); }
    if( 'string' !== typeof note_id ) { throw new Error("[NancyNotesBackend_GetNoteById] 'note_id' not a string."); }
    return AzureBaseAPICall({
        endpoint: endpoint,
        queryParams: {note_id: note_id},
        method: method,
        body: null,
        signal: abortSignal
    });
} // End fu NancyNotesBackend_GetNoteById

export async function NancyNotesBackend_CreateNote({ aad_user_email, document_title, document_path, document_tags, content_json, content_text }, abortSignal=null) {
    const endpoint = 'HTNancyNotesCreateNote';
    const queryParams = {caller: 'NancyNotesBackend_CreateNote'};
    const method = 'PUT';
    // TODO: Add some input validation
    const body = {
        aad_user_email: aad_user_email,     // <String> Email address
        document_title: document_title,     // <String> Title with any printable characters
        document_path: document_path,       // <String> String denoting path starting with "/", e.g. "/path/to/document"
        document_tags: document_tags,       // <Array of Strings> or empty array or null
        document_json_content: content_json,  // <String> or empty string or null
        document_text_content: content_text,  // <String> or empty string or null
    };
    //console.log("[NancyNotesBackend_CreateNote] body:", body);

    return AzureBaseAPICall({
        endpoint: endpoint,
        queryParams: queryParams,
        method: method,
        body: body,
        signal: abortSignal
    });
}; // End fu NancyNotesBackend_CreateNote


export async function NancyNotesBackend_UpdateNoteTitle(note_id, updated_note_title, abortSignal=null) {
    const queryParams = {caller: 'NancyNotesBackend_UpdateNoteTitle'};
    let endpoint = 'HTNancyNotesUpdateNoteTitleById';
    let method = 'PUT';
    let body = {
        document_id: note_id,
        updated_document_title: updated_note_title,
    };
    //console.log(`[NancyNotesBackend_UpdateNoteTitle] note_id = ${note_id} and updated_note_title = ${updated_note_title}.`)
    return AzureBaseAPICall({
        endpoint: endpoint,
        queryParams: queryParams,
        method: method,
        body: body,
        signal: abortSignal
    });
}; // End fu NancyNotesBackend_UpdateNoteTitle



export async function NancyNotesBackend_UpdateNoteVirtualPath(note_id, updated_note_virtual_path, abortSignal=null) {
    if( ! updated_note_virtual_path || ! Array.isArray(updated_note_virtual_path) ) {
        // Deal with error
        console.error("[ERROR] NancyNotesBackend_UpdateNoteVirtualPath> Unable to send update path request to endpoint HTNancyNotesUpdateNoteVirtualPathById because 2nd argument (updated_note_virtual_path) not an array." )
        return null;
    }
    const queryParams = {caller: 'NancyNotesBackend_UpdateNoteVirtualPath'};
    let endpoint = 'HTNancyNotesUpdateNoteVirtualPathById';
    let method = 'PUT';
    let body = {
        document_id: note_id,
        updated_document_path: updated_note_virtual_path,
    };
    //console.log(`[NancyNotesBackend_UpdateNoteVirtualPath] note_id = ${note_id} and updated_note_virtual_path = ${updated_note_virtual_path}.`)
    return AzureBaseAPICall({
        endpoint: endpoint,
        queryParams: queryParams,
        method: method,
        body: body,
        signal: abortSignal
    });
}; // End fu NancyNotesBackend_UpdateNoteVirtualPath

/**
 * Updates the tags of a note in the Nancy Notes database.
 *
 * @function
 * @async
 * @param {String} note_id - The ID of the note to update.
 * @param {Array<String>} updated_note_tags - An array of the updated tags as strings.
 * @returns {Promise<Object>} - A promise that resolves with the response from the API call.
 * @throws {Error} - If the `updated_note_tags` parameter is not an array of strings.
 */
export async function NancyNotesBackend_UpdateNoteTags(note_id, updated_note_tags, abortSignal=null) {
    if (!updated_note_tags || !Array.isArray(updated_note_tags) || !updated_note_tags.every(tag => typeof tag === 'string')) {
        // Deal with error
        console.error("[ERROR] NancyNotesBackend_UpdateNoteTags> Unable to send update tags request to endpoint HTNancyNotesUpdateNoteTagsById because 2nd argument (updated_note_tags) is not an array of strings.");
        throw new Error("[NancyNotesBackend_UpdateNoteTags] Invalid argument: 'updated_note_tags' must be an array of strings.");
        //return null; // Throw an error instead
    }
    // Filter out any empty strings:
    updated_note_tags = updated_note_tags.filter(tag => tag.trim() !== '');
    const queryParams = { caller: 'NancyNotesBackend_UpdateNoteTags' };
    let endpoint = 'HTNancyNotesUpdateNoteTagsById';
    let method = 'PUT';
    let body = {
        document_id: note_id,
        updated_document_tags: updated_note_tags,
    };
    //console.log(`[NancyNotesBackend_UpdateNoteTags] note_id = ${note_id} and updated_note_tags = ${updated_note_tags}.`);
    return AzureBaseAPICall({
        endpoint: endpoint,
        queryParams: queryParams,
        method: method,
        body: body,
        signal: abortSignal
    });
}; // End fu NancyNotesBackend_UpdateNoteTags


export async function NancyNotesBackend_UpdateNoteContent(note_id, note_json_content="", note_text_content="", abortSignal=null) {
    // Check if note_json_content is a valid non-empty string
    if( typeof note_json_content !== "string" || note_json_content.trim() === "" ) {
        throw new Error("[NancyNotesBackend_UpdateNoteContent] Invalid argument: 'note_json_content' must be a non-empty string.");
    }

    // Check if note_text_content is a valid non-empty string
    if (typeof note_text_content !== "string") {
        throw new Error("[NancyNotesBackend_UpdateNoteContent] Invalid argument: 'note_text_content' must be if type string.");
    }

    const queryParams = {caller: 'NancyNotesBackend_UpdateNoteContent'};
    let endpoint = 'HTNancyNotesUpdateNoteContentById';
    let method = 'PUT';
    let body = {
        document_id: note_id,
        updated_json_content: note_json_content,
        updated_text_content: note_text_content
    };
    //console.log(`[NancyNotesBackend_UpdateNoteContent] note_id = ${note_id}.`)
    return AzureBaseAPICall({
        endpoint: endpoint,
        queryParams: queryParams,
        method: method,
        body: body,
        signal: abortSignal
    });
}; // End fu NancyNotesBackend_UpdateNoteContent



/**
 * 
 * @param {*} queryParams 
 * @returns 
 */
export async function NancyNotesBackend_ListNotes({ startDate, endDate }, abortSignal=null) {

    //console.log(titleSearchTerm);
    if( 'string' === typeof startDate ) {
        startDate = startDate.trim();
        if( ! startDate.length ) { startDate = null; }
    } else { startDate = null; }
    if( 'string' === typeof endDate ) {
        endDate = endDate.trim();
        if( ! endDate.length ) { endDate = null; }
    } else { endDate = null; }


    // Set up the request to the backend api:
    let queryParams = {
        caller: 'NancyNotesBackend_ListNotes'
    };
    let endpoint = 'HTNancyNotesList';
    let method = 'POST';
    let body = {
        start_date: startDate,
        end_date: endDate
    };
    //console.log("[NancyNotesBackend_ListNotes] Body:", body);

    return AzureBaseAPICall({
        endpoint: endpoint,
        queryParams: queryParams,
        method: method,
        body: body,
        signal: abortSignal
    });
} // End fu NancyNotesBackend_ListNotes


/**
 * 
 * @param {*} queryParams 
 * @returns 
 */
export async function NancyNotesBackend_SearchNotes({ titleSearchTerm, pathSearchTerm, tagSearchTerm, contentSearchTerm, startDate, endDate }, abortSignal=null) {
    //console.log("[NancyNotesBackend_ListSearch] Input:", JSON.stringify({ titleSearchTerm, pathSearchTerm, tagSearchTerm, contentSearchTerm, startDate, endDate }, null, 2));
    let start_date = null;
    if( 'string' === typeof startDate ) {
        start_date = startDate.trim();
        if( ! start_date.length ) { start_date = null; }
    } else { start_date = null; }
    let end_date = null;
    if( 'string' === typeof endDate ) {
        end_date = endDate.trim();
        if( ! end_date.length ) { end_date = null; }
    } else { end_date = null; }

    // Extract tags and put it in an array:
    let tags = [];
    //console.log("Tag search term:", tagSearchTerm);
    if( tagSearchTerm && 'string' === typeof tagSearchTerm ) {
        // Split on space, comma, (semi-) colon. Trim and strip away empty strings:
        tags = tagSearchTerm.split(/[\s,;:]+/).map(tag => tag.trim()).filter(tag => tag !== '');
    }
    if( ! Array.isArray(tags) || tags.length < 1 ) {
        tags = null;
    }
    // Check the title:
    let title;
    //console.log(titleSearchTerm);
    if( 'string' === typeof titleSearchTerm ) {
        title = titleSearchTerm.trim();
        if( ! title.length ) { title = null; }
    } else { title = null; }
    // Check the path:
    let path;
    //console.log(pathSearchTerm);
    if( 'string' === typeof pathSearchTerm ) {
        path = pathSearchTerm.trim();
        if( ! path.length ) { path = null; }
    } else { path = null; }
    // Check the content:
    let content;
    //console.log(contentSearchTerm);
    if( 'string' === typeof contentSearchTerm ) {
        content = contentSearchTerm.trim();
        if( ! content.length ) { content = null; }
    } else { content = null; }

    // Set up the request to the backend api:
    let queryParams = {
        caller: 'NancyNotesBackend_SearchNotes'
    };
    let endpoint = 'HTNancyNotesSearch';
    let method = 'POST';
    let body = {
        tag_filter: tags,
        title_filter: title,
        path_filter: path,
        content_filter: content,
        start_date: start_date,
        end_date: end_date
    };
    //console.log("[NancyNotesBackend_ListSearch] Body:", JSON.stringify(body, null, 2));

    return AzureBaseAPICall({
        endpoint: endpoint,
        queryParams: queryParams,
        method: method,
        body: body,
        signal: abortSignal
    });
} // End fu NancyNotesBackend_SearchNotes


export async function NancyNotesBackend_ListUniquePaths() {
    let queryParams = {
        caller: 'NancyNotesBackend_ListUniquePaths'
    };
    let endpoint = 'HTNancyNotesListUniquePaths';
    let method = 'GET';

    return AzureBaseAPICall({
        endpoint: endpoint,
        queryParams: queryParams,
        method: method,
        body: null
    });
} // End fu NancyNotesBackend_ListUniquePaths


export async function NancyNotesBackend_DeleteNoteById(note_id) {
    const endpoint = 'HTNancyNotesDeleteNoteById';
    const method = 'DELETE';
    //console.log("[NancyNotesBackend_DeleteNoteById] Deleting note with id [" + note_id + "]");
    // note_id = "646d519469c26bdf7e378292"
    const regex = new RegExp(/^[0-9a-fA-F]{12,48}$/);
    if ( ! regex.test(note_id) ) {
        console.error("[ERROR] NancyNotesBackend_DeleteNoteById> Unable to send delete note request to endpoint HTNancyNotesDeleteNoteById because 1st argument (note_id) is not a valid document ID:", note_id);
        throw new Error("[NancyNotesBackend_DeleteNoteById] Invalid argument: 'note_id' must be a valid document ID.");
    }
    const queryParams = {
        caller: 'NancyNotesBackend_DeleteNoteById',
        document_id: note_id
    };

    //console.log(`[NancyNotesBackend_DeleteNoteById] note_id = ${note_id}.`)
    return AzureBaseAPICall({
        endpoint: endpoint,
        queryParams: queryParams,
        method: method,
        body: null
    });
} // End fu NancyNotesBackend_DeleteNoteById