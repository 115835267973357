import React, { useState, useContext ,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Divider } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import { AzureAuthContext } from "../azure-components/AzureUserInfo";


export default function NancyNavigationPopupMenu() {
    const { instance, userIsLoggedIn, accountInfo } = useContext(AzureAuthContext);
    const navigator = useNavigate();
    // State for managing the settings menu
    const [anchorElement, setAnchorElement] = useState(null);
    const open = Boolean(anchorElement);

    // Open the settings menu
    const handleNavMenuClick = (event) => {
        setAnchorElement(event.currentTarget);
    };

    // Close the settings menu
    const handleNavMenuClose = () => {
        setAnchorElement(null);
    };

    const guestNavigationMenu = (
        <Menu
            className="NancyPopupMenu"
            anchorEl={anchorElement}
            open={open}
            onClose={handleNavMenuClose}
            onClick={handleNavMenuClose}
            PaperProps={{ elevation: 4 }}
        >
            <MenuItem onClick={() => navigator("/")}>
                <ListItemIcon>
                    <HomeIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Home" />
            </MenuItem>
            <MenuItem onClick={() => navigator("/about")}>
                <ListItemIcon>
                    <SettingsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="About" />
            </MenuItem>
        </Menu>
    );

    const userNavigationMenu = (
        <Menu
            className="NancyPopupMenu"
            anchorEl={anchorElement}
            open={open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            onClose={handleNavMenuClose}
            onClick={handleNavMenuClose}
            PaperProps={{ elevation: 4 }}
        >
            <MenuItem onClick={() => navigator("/")}>
                <ListItemIcon>
                    <HomeIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Home" />
            </MenuItem>
            <MenuItem onClick={() => navigator("/about")}>
                <ListItemIcon>
                    <SettingsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="About" />
            </MenuItem>
            <Divider sx={{fontVariant: 'small-caps'}}>Modules</Divider>
            <MenuItem onClick={() => navigator("/notes")}>
                <ListItemIcon>
                    <SettingsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Notes" />
            </MenuItem>
            <Divider sx={{fontVariant: 'small-caps'}}>Testing</Divider>
            <MenuItem onClick={() => navigator("/af/test")}>
                <ListItemIcon>
                    <SettingsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Function testing" />
            </MenuItem>
        </Menu>
    );

    return (
        <>
            <IconButton edge="start" className="" color="inherit" aria-label='menu'  style={{ border: '0px solid yellow' }}
                onClick={handleNavMenuClick}
            >
                <MenuIcon />
            </IconButton>
            {userIsLoggedIn ? userNavigationMenu : guestNavigationMenu}
        </>
    );
}
