import React, { useState, useEffect } from "react";
import { Box, Chip, TextField, Typography } from "@mui/material";
//import { useNancyStatusContext } from "../../../../components/nancy-contexts/NancyStatusContex"; // NANCY_STATUS
import { NancyNotesBackend_UpdateNoteTags } from "../../services/NancyNotesBackendInterface";

/**
 * updateTagsInDB is an async function that updates the tags of a note in the backend.
 *
 * @async
 * @function
 * @param {string} NancyNoteId - The ID of the note for which the tags are being updated.
 * @param {string[]} tag_list - An array of tags to be updated.
 * @returns {Promise<null>} - Returns null if the operation is unsuccessful.
 */
const updateTagsInDB = async (NancyNoteId, tag_list) => {
    //console.log("[DEBUG::updateTagsInDB] Updating tag list:", tag_list);
    if ( ! NancyNoteId ) {
        console.error("[ERROR::updateTagsInDB] NancyNoteId unavailable. Cannot update.");
        return null;
    }
    try {
        const res = await NancyNotesBackend_UpdateNoteTags(NancyNoteId, tag_list);
        if( ! res ) {
            console.error("[ERROR::updateTagsInDB] Call to NancyNotesBackend_UpdateNoteTags did not return a result.");
            // TODO: Handle error.
            return null;
        }
    }
    catch(e) {
        console.error("[ERROR::updateTagsInDB] Call to NancyNotesBackend_UpdateNoteTags threw an error.");
        // Add actual error handling, like cleaning up the tags.
        return null;
    }
} // End fu updateTagsInDB


/**
 * NancyNoteTags is a React functional component responsible for rendering and
 * managing the tags associated with a note. It handles switching between view and
 * edit modes, and updates the tags in the backend when changes are made.
 *
 * @component
 * @param {string} NancyNoteId - The ID of the note for which the tags are being displayed.
 * @param {string[]} NancyNoteTagsArray - An array of tags associated with the note.
 */
export default function NancyNoteTags({ NancyNoteId, NancyNoteTagsArray }) {
    
    // Use the global Nancy Status Context to update the status field in the footer when updating the title:
    //const { updateGlobalNancyStatusMessage, updateTransientNancyStatusMessage } = useNancyStatusContext();

    const [tagListArray, setTagListArray] = useState([]);
    const [tagListString, setTagListString] = useState("");
    const [tagEditModeEnabled, setTagEditModeEnabled] = useState(false);

    // Reacts to incoming changes to NancyNoteTagsArray
    // And updates the relevant variables.
    useEffect(() => {
        if( NancyNoteTagsArray && Array.isArray(NancyNoteTagsArray) ) {
            setTagListArray(NancyNoteTagsArray);
            setTagListString(NancyNoteTagsArray.join(", "));
        }
    }, [NancyNoteTagsArray])


    // EVENT HANDLER: HANDLE TAGLINE DOUBLE CLICK
    // Event handler for when the user doubleclicks the tag line (<Box>).
    // It will change the styled <Box><Grip>...</Box> line into an editable <Textfiled>.
    const handleTaglineDoubleClick = (e) => {
        setTagEditModeEnabled(true);
    }; // End event handler handleTaglineDoubleClick

    // EVENT HANDLER: HANDLE TAG TEXTFIELD ENTER KEY
    // Event handler for the tag list Textfield.
    // If the key pressed is ENTER, trigger the onBlur event.
    // This will result in the Textfield changing back to the styled Grip tags.
    const handleTagListKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.target.blur();
        }
    }; // End event handler handleTagListKeyDown

    /**
     * handleTagListBlur is an async event handler for the tag list TextField blur event.
     * It updates the local state and saves the updated tags to the backend. It also
     * converts the editable <Textfield> component back to the styled version
     * of <Grip>s inside a <Box>.
     *
     * @async
     * @function
     * @param {Object} e - The event object from the TextField onBlur event.
     */
    const handleTagListBlur = async (e) => {
        // update fag list and save changed tags
        const newTagList = tagListString
            .split(",") // Split on comma
            .filter((tag) => tag.trim() !== "") // trim white space
            .map((tag) => tag.trim().toLowerCase()) // lowercase tags both for filtering and sorting
            .filter((tag, index, self) => self.indexOf(tag) === index) // remove duplicate tags
            .sort((a, b) => a.localeCompare(b)); // sort case insensitive
        setTagListArray(newTagList);
        setTagListString(newTagList.join(", "));
        setTagEditModeEnabled(false);
        try {
            await updateTagsInDB(NancyNoteId, newTagList);
        }
        catch(e) {
            console.error('[ERROR::handleTagListBlur] Call to updateTagsInDB threw an error.', e);
        }
    }; // end event handler handleTagListBlur

    /**
     * handleDeleteTag is an async event handler that removes a tag from the list of tags.
     * It updates the local state and saves the updated tags to the backend.
     *
     * @async
     * @function
     * @param {string} tagToDelete - The tag to be removed from the list of tags.
     */
    const handleDeleteTag = async (tagToDelete) => {
        //console.log("Deleting tag", tagToDelete);
        // Update the tagListArray to exclude the deleted tag
        const updatedTagList = tagListArray.filter(
            (tag) => tag !== tagToDelete
        );
        setTagListArray(updatedTagList);
        setTagListString(updatedTagList.join(", "));
        // Update tags on backend:
        try {
            await updateTagsInDB(NancyNoteId, updatedTagList);
        }
        catch(e) {
            console.error('[ERROR::handleDeleteTag] Call to handleDeleteTag threw an error.', e);
            // TODO: Handle error in a more meaningful way
        }
    }; // End event handler handleDeleteTag

    /**
     * The return statement of the NancyNoteTags component.
     * It conditionally renders either the TextField for editing the tags or the Chip components
     * for displaying the tags, based on the tagEditModeEnabled state.
     *
     * @returns {JSX.Element} - The rendered component containing either the TextField or Chip components.
     */
    return (
        <Box
            sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                mb: 1,
                "&:hover": { cursor: "pointer" },
            }}
            onDoubleClick={handleTaglineDoubleClick}
        >
            <Typography
                variant="subtitle2"
                component="span"
                sx={{ fontWeight: "bold", mr: 1 }}
            >
                Tags
            </Typography>
            {tagEditModeEnabled ? (
                <TextField
                    value={tagListString}
                    onChange={(e) => setTagListString(e.target.value)}
                    onBlur={handleTagListBlur}
                    onKeyDown={handleTagListKeyDown}
                    autoFocus
                    fullWidth
                    size="small"
                    sx={{ padding: "4px" }}
                />
            ) : (
                tagListArray.map((tag) => (
                    <Chip
                        key={tag}
                        label={tag}
                        onDelete={() => handleDeleteTag(tag)}
                        sx={{
                            padding: "2px 0",
                            height: "auto",
                            fontSize: "0.8rem",
                            "& .MuiChip-deleteIcon": {
                                display: "none",
                                fontSize: "0.7rem",
                                color: "red",
                            },
                            "&:hover .MuiChip-deleteIcon": {
                                display: "inline-flex",
                                color: "red",
                            },
                        }}
                    />
                ))
            )}
        </Box>
    );
} // End Function Component <NancyNoteTags>
