// in modules/NancyNotes/routes.js
import { NancyNotes, NancyNote } from "../";
import { Route, Routes } from "react-router-dom";

export default function NancyNotesRoutes() {
    // Path relative to the current route
    // <Route path="/notes" element={<NancyNotesRoutes />} />
    // which is /notes, so / means /notes/ and /note/id/:noteId means /notes/note/id/:noteId
    return (
        <Routes>
            <Route path="/" element={<NancyNotes />} />
            <Route path="/note/id/:noteId" element={<NancyNote />} />
        </Routes>
    );
}
