import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { NancyNotesBackend_SearchNotes } from '../../services/NancyNotesBackendInterface';
import { Popper, TextField, Box, List, ListItemButton, ListItemText, Paper } from '@mui/material';


// Custom hook for debouncing
function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
  
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);
  
    return debouncedValue;
}

export default function NancyNoteSearchWidget() {
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 2000);
    const [results, setResults] = useState([]);
    const [cursor, setCursor] = useState(-1);
    const [anchorEl, setAnchorEl] = useState(null);
    const textFieldRef = useRef(null);
    const [inputValue, setInputValue] = useState('');
    const [searchBoxIsBlurring, setSearchBoxIsBlurring] = useState(false);

    const navigator = useNavigate();

    // Delay clearing the search box and results when the user blurs the search box
    // so the onMouseDown event on the search result item can fire first.
    // It normally works without this delay, while onClick and onMouseUp doesn't.
    // Adding it to be sure. May want to figure out a way to make the other two
    // events work too by having more elaborate checks before calling resetSearch.
    useEffect(() => {
        //console.log("searchBoxIsBlurring:", searchBoxIsBlurring)
        if (searchBoxIsBlurring) {
            const timeoutId = setTimeout(() => resetSearch(), 50);
            return () => clearTimeout(timeoutId); // This clears the timeout when the component is unmounted    
        }
    }, [searchBoxIsBlurring]);

    // Fetch search results from backend when the debouncedSearchTerm changes:
    useEffect(() => {
        const abortController = new AbortController();
        const abortSignal = abortController.signal;
        if (debouncedSearchTerm) {
            //{ titleSearchTerm, pathSearchTerm, tagSearchTerm, contentSearchTerm, startDate, endDate }
            const search = {
                titleSearchTerm: debouncedSearchTerm,
                pathSearchTerm: null,
                tagSearchTerm: debouncedSearchTerm,
                contentSearchTerm: debouncedSearchTerm,
                startDate: null,
                endDate: null
            };
            NancyNotesBackend_SearchNotes(search, abortSignal).then(response => {
                try {
                    //console.log("NancyNotesBackend_ListNotes response:", JSON.stringify(response, null, 2));
                    setResults(response.result.documents);
                } catch(error) {
                    //console.error("NancyNotesBackend_ListNotes didn't return a valid response", JSON.stringify(response, null, 2));
                    setResults([]);
                    return;
                }
            });
        } else {
            setResults([]);
        }
        // Cleanup function. Runs when the component unmounts.
        // Important to abort reuqests to the backend when the component unmounts.
        return () => {
            abortController.abort();
        }
    }, [debouncedSearchTerm]);

    // Update the search term when the user types in the search box:
    useEffect(() => {
        setSearchTerm(inputValue);
    }, [inputValue]);
    
    // Reset all search related states to clear the search box and results:
    const resetSearch = () => {
        setSearchTerm('');
        setInputValue('');
        setResults([]);
        setSearchBoxIsBlurring(false);
    }; // End fu resetSearch

    // Navigate to a specific note:
    const navigateToNote = (noteId) => {
        //console.log("Navigating to note with id:", noteId);
        navigator('/notes/note/id/' + noteId);
    }; // End fu navigateToNote

    // Event handler for the keyDown event on the TextField
    // Used to fire off searches, reset search, navigate to a note, etc.
    const handleTextFieldKeyDown = (e) => {
        // User hit ESC, reset and blur the search field:
        if (e.key === 'Escape') {
            resetSearch();
            e.target.blur();
        }
        // User hit the down arrow, move cursor down in the Popper List:
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setCursor(prevCursor => (prevCursor < results.length - 1 ? prevCursor + 1 : prevCursor));
        }
        // User hit the up arrow, move cursor up in the Popper List:
        if (e.key === 'ArrowUp') {
            e.preventDefault();
            setCursor(prevCursor => (prevCursor > 0 ? prevCursor - 1 : 0));
        }
        // User hit the Enter key, navigate to the selected result item in the Popper List:
        if (e.key === 'Enter') {
            e.preventDefault();
            // navigate to selected result
            if (cursor >= 0) {
                const selectedResult = results[cursor];
                resetSearch();
                e.target.blur();
                navigateToNote(selectedResult._id);
            }
        }
    }; // End fu handleTextFieldKeyDown

    // BUG: Acceptable for now, will fix later.
    // When the user clicks on the TextField, the Popper is shown,
    // but it is shown at the wrong position and z-index. It is shown
    // under the header (toolbar?) and not directly under the TextField.
    // It is also displayed "lower" than the header, so the header will
    // cover the top border of the Popper if you set it.
    // Also, Popper may not be the ideal component to use here. Should
    // look into using Menu instead, like for the nav menu and user menu,
    // since it works better with the header (regarding positioning and z-index).
    return (
        <Box
            display="flex"
            flexDirection="rows"
            marginRight="1em"
            alignSelf="center" // Align vertically in parent
            justifyContent="middle" // Align horizontally in parent
        >
            <TextField 
                ref={textFieldRef}
                variant='outlined'
                size="small" // Make TextField smaller
                InputProps={{
                    style: { 
                        height: 36,
                        width: 250,
                        padding: '0 10px',
                        fontSize: 14,
                        color: "#1D354A",
                        border: '1px solid #1D354A'
                        //backgroundColor: "#CFDEEC", // will override the Mui-stuff in the sx prop
                     }
                }}
                sx={{
                    // The fieldset label:
                    '& label.Mui-focused': {
                        color: '#1D354A',
                        fontWeight: 'bold',
                    },
                    '& .MuiOutlinedInput-root': {
                        // Default style of the TextField
                        '&': {
                            backgroundColor: "#CFDEEC",
                        },
                        // When hovered:
                        '&:hover': {
                            backgroundColor: "#F0F4F9",
                        },
                        // When focused:
                        '&.Mui-focused': {
                            backgroundColor: "#F0F4F9",
                        },
                        // Remove the default MUI border around the TextField (and input):
                        // The default border is thick and doesn't look nice.
                        '& fieldset': {
                            border: 'none',
                        },
                        '&:hover fieldset': {
                            border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                            border: 'none',
                        },
                    },
                }}
                onChange={(e) => setInputValue(e.target.value)}
                onBlur={(e) => setSearchBoxIsBlurring(true)}
                value={inputValue}
                onKeyDown={handleTextFieldKeyDown}
                label="Search"
            />
            <Popper open={Boolean(results.length)} anchorEl={textFieldRef.current}>
                <Paper elevation={4}
                    sx={{
                        zIndex: (theme) => theme.zIndex.drawer + 1000, // not helping
                        minWidth: 244,
                    }}
                >
                    <List>
                        {results.map((result, index) => (
                            <ListItemButton key={result._id} selected={index === cursor} onMouseDown={(e) => { e.stopPropagation(); navigateToNote(result._id) }}

                                sx={{
                                    '&:hover': {
                                        backgroundColor: "#F0F4F9",
                                        color: "#0C3B7B",
                                        cursor: "pointer"
                                    }
                                }}
                            >
                                <ListItemText primary={result.title}  />
                            </ListItemButton>
                        ))}
                    </List>
                </Paper>
            </Popper>
        </Box>
    );
} // End Functional Component <NancyNoteSearchWidget>
