import { useMsal } from "@azure/msal-react";

export default function AzureLogoutButton() {
    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logout();
    };

    return (
        <button onClick={handleLogout}>Logout from Azure AD</button>
    );
};
