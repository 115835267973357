import React from 'react';
import { NavLink } from 'react-router-dom';

/**
 * This code block demonstrates how to create an isActive effect on
 * menu buttons using React v19, React Router v6 and Material-UI v5.
 * For more information and examples, refer to the following resources:
 * https://www.youtube.com/watch?v=uFSu6tgYKRY
 * https://stackoverflow.com/questions/71350481/react-router-dom-v6-navlink-and-mui-listitem-not-working-with-classname
 */
// Custom NancyNavLink component that uses NavLink from react-router-dom v6.
// This component accepts a className and activeClassName prop and combines them when the link is active.
const NancyNavLink = React.forwardRef((props, ref) => (
    <NavLink
        ref={ref}
        to={props.to}
        className={({ isActive }) => {
            return isActive ? `${props.className} ${props.activeClassName}` : props.className;
        }}
        style={({isActive}) => ({fontWeight: isActive ? 'bold !important' : 'normal'})}
    >
      {props.children}
    </NavLink>
));

export { NancyNavLink as default };
