import React, { useContext } from "react";
import { MsalAuthenticationTemplate  } from "@azure/msal-react";
import { Navigate, Outlet } from "react-router-dom";
import { AzureAuthContext } from "../azure-components/AzureUserInfo";

export default function ProtectedRoute ({ component: Component, ...rest }) {
    const { userIsLoggedIn } = useContext(AzureAuthContext);
    if( ! userIsLoggedIn ) {
        return <Navigate to={"/"} replace />
    }
    return (
        <MsalAuthenticationTemplate>
            <Outlet />
        </MsalAuthenticationTemplate>
    );
};
