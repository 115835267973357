import React, { createContext } from "react";
import ReactDOM from "react-dom/client";
//import { BrowserRouter as Router} from "react-router-dom";
import { HashRouter as Router} from "react-router-dom";
import "./resources/styles/index.css";
import App from "./App";

import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { NancyMSALAuthConfig } from "./configurations/NancyMSALAuthConfig";
import { AzureUserInfo } from "./components/azure-components/AzureUserInfo";
import { NancyStatusProvider } from "./components/nancy-contexts/NancyStatusContex";
import { NancyNotesDataProvider } from "./modules/NancyNotes/contexts/NancyNotesContext";

// Create a new instance of the Azure AD PublicClientApplication, using the NancyMSALAuthConfig configuration object.
export const MSALInstance = new PublicClientApplication(NancyMSALAuthConfig);
// Retrieve an array of all accounts currently signed in to the application. Empty if not signed in.
const accounts = MSALInstance.getAllAccounts()
// If there is at least one account signed in, set the first account in the array as the active account.
if (accounts.length > 0) {
    MSALInstance.setActiveAccount(accounts[0])
}
// Add an event callback to handle successful login events.
MSALInstance.addEventCallback(event => {
    // Check if the event type is a successful login and an account is included in the payload.
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
         // Set the newly signed-in account as the active account for the application.
        const account = event.payload.account;
        MSALInstance.setActiveAccount(account);
    }
    // TODO: Handle login errors.
});

// React render:
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <MsalProvider instance={MSALInstance}>
            <AzureUserInfo>
                <Router>
                    <NancyNotesDataProvider>
                        <NancyStatusProvider>
                            <App />
                        </NancyStatusProvider>
                    </NancyNotesDataProvider>
                    </Router>
            </AzureUserInfo>
        </MsalProvider>
    </React.StrictMode>
);
