import React, { useRef, useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Button, CircularProgress, Divider } from "@mui/material";
import { useNancyStatusContext, NANCY_STATUS } from "../../../../components/nancy-contexts/NancyStatusContex";
import { NancyNotesBackend_DeleteNoteById, NancyNotesBackend_UpdateNoteContent } from "../../services/NancyNotesBackendInterface";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { useNancyNotesDataContext } from "../../contexts/NancyNotesContext";

const quillModules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        ["blockquote"],
        [{ list:  "ordered" }, { list:  "bullet" }],
        [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
        ["link", "image"]
    ]
}; // end quillModules settings

const s_key = 83;
const enter_key = 13;
const escape_key = 27;

const isJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};


/**
 * Converts a Quill Delta object into an HTML string using the QuillDeltaToHtmlConverter.
 *
 * @param {Object} input - The Quill Delta object to be converted into HTML.
 * @returns {string} - The HTML string representation of the Quill Delta object. Returns an empty string if an error occurs during conversion.
 */
const convertDeltaToHtml = (input) => {
    //console.log("[convertDeltaToHtml] (1) Running function.")
    try {
      const converter = new QuillDeltaToHtmlConverter(input.ops, {});
      //console.log("[convertDeltaToHtml] (4) Converted JSON to Delta format.")
      const html = converter.convert();
      //console.log("[convertDeltaToHtml] (5) Content:", html);
      return html;
    } catch (error) {
      //console.error("Error converting delta to HTML:", error);
      return "";
    }
};


/**
 * NancyNoteContent is a React functional component responsible for rendering
 * note content using ReactQuill and handling content editing.
 *
 * @component
 * @param {Object} props - React properties passed to the component
 * @param {number} props.NancyNoteId - The ID of the note
 * @param {string} props.NancyNoteContent - The content of the note
 */
export default function NancyNoteContent({ NancyNoteId, NancyNoteContent }) {
    
    // Use the global Nancy Status Context to update the status field in the footer when updating the title:
    const { updateTransientNancyStatusMessage } = useNancyStatusContext(); // updateGlobalNancyStatusMessage

    // Navigation capabilities:
    const location = useLocation();
    const navigator = useNavigate();

    const { NancyNotesRemoveNote } = useNancyNotesDataContext();

    // State variables:
    // To put the editor in read only mode. Used to "disable" the editor:
    const [quillEditorReadOnlyMode, setQuillEditorReadOnlyMode] = useState(true);
    // To display the HTML version of the content. Used to "hide" the editor:
    const [quillEditorHTMLDisplay, setQuillEditorHTMLDisplay] = useState(true);
    // To display the saving widget. Used to "hide" the editor while saving:
    const [quillEditorIsSaving, setQuillEditorIsSaving] = useState(false);
    // To store the current note content in Quill Delta format:
    const [quillEditorDelta, setQuillEditorDelta] = useState({ops: [{ insert: "" }]});

    // Delete dialog state:
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);

    // Referenses:
    const quillRef = useRef(null);
    const contentDiv = useRef(null);

    // Works with react-router-dom's Browserrouter:
    // const removeEditQueryParam = () => {
    //     const currentUrl = new URL(window.location.href);
    //     currentUrl.searchParams.delete("edit");
    //     window.history.replaceState(null, null, currentUrl.toString());
    // };
    // Updated to work with react-router-dom's HashRouter:
    const removeEditQueryParam = () => {
        const currentUrl = new URL(window.location.href);
        let hashParams = new URLSearchParams(currentUrl.hash.slice(currentUrl.hash.indexOf('?') + 1));
        hashParams.delete("edit");
        // NB: Cannot remove "?". That leads to a weird and unexpected error.
        window.location.hash = currentUrl.hash.slice(0, currentUrl.hash.indexOf('?')) + "?" + hashParams.toString();
    };
    

    useEffect(() => {
        //console.log("[NancyNoteContent] (1) Running useEffect hook.")
        //console.log("[NancyNotesContent.js] Saving state is:", quillEditorIsSaving)
    }, [quillEditorIsSaving]);

    /**
     * saveContentAndSwitchToReadOnlyMode is an asynchronous function responsible
     * for saving the current note content to the backend, updating the note's
     * content HTML, and switching the editor back to read-only mode.
     *
     * @async
     */
    const saveContentAndSwitchToReadOnlyMode = async () => {
        try {
            // Update status:
            updateTransientNancyStatusMessage("Saving content...", NANCY_STATUS.WAITING);
            // "DISABLE" THE QUILL EDITOR HERE AND SHOW A SAVING WIDGET OF SOME KIND"
            setQuillEditorIsSaving(true);
            // Switch to read only mode:
            setQuillEditorReadOnlyMode(true);
            // Prepare content for saving:
            const qed = quillRef.current.getEditor();
            const delta = qed.getContents();
            const json_content = JSON.stringify(delta);
            const text_content = qed.getText();
            // Saving content to backend:
            //console.log("[saveContentAndSwitchToReadOnlyMode] Saving JSON content:", json_content);
            //console.log("[saveContentAndSwitchToReadOnlyMode] Saving TEXT content:", text_content);
            const res = await NancyNotesBackend_UpdateNoteContent(NancyNoteId, json_content, text_content);
            if( res ) {
                // Update the delta state
                setQuillEditorDelta(delta);
                // REMOVE THE SAVING WIDGET HERE AND ENABLE THE QUILL EDITOR AGAIN BEFORE WE SWITCH TO READ ONLY MODE
                setQuillEditorIsSaving(false);
                // Update the content div:
                updateContentDivInnerHTML(convertDeltaToHtml(delta));
                //console.log("[saveContentAndSwitchToReadOnlyMode] Result from NancyNotesBackend_UpdateNoteContent:", res);
                updateTransientNancyStatusMessage("Content saved.", NANCY_STATUS.SUCCESS);
                setQuillEditorHTMLDisplay(true);
            }
            else {
                updateTransientNancyStatusMessage("Error saving content.", NANCY_STATUS.ERROR);
                setQuillEditorIsSaving(false);
            }
        } catch (error) {
            //console.error("[saveContentAndSwitchToReadOnlyMode] Error saving content:", error);
            updateTransientNancyStatusMessage("Error saving content.", NANCY_STATUS.ERROR);
            // Switch to back to edit mode:
            setQuillEditorReadOnlyMode(false);
        } finally {
            // When operation has finished, success or not, indicate that saving is no longer in progress
            setQuillEditorIsSaving(false);
        }
    }; // End fu saveContentAndSwitchToReadOnlyMode

    const deleteNote = async () => {
        try {
            // Update status:
            //console.log("[deleteNote] Deleting note with id [" + NancyNoteId + "]");
            updateTransientNancyStatusMessage("Deleting note with id", NANCY_STATUS.WAITING);
            // Delete note:
            const res = await NancyNotesBackend_DeleteNoteById(NancyNoteId);
            if( res ) { // res.status === 200
                //console.log("[deleteNote] Result from NancyNotesBackend_DeleteNote:", res);
                updateTransientNancyStatusMessage("Note deleted.", NANCY_STATUS.SUCCESS);
                NancyNotesRemoveNote(NancyNoteId);
                // Redirect to notes list:
                navigator("/notes");
            }
            else {
                //console.error("[deleteNote] Result from NancyNotesBackend_DeleteNote:", res);
                updateTransientNancyStatusMessage("Error deleting note.", NANCY_STATUS.ERROR);
            }
        } catch (error) {
            //console.error("[deleteNote] Error deleting note:", error);
            updateTransientNancyStatusMessage("Error deleting note.", NANCY_STATUS.ERROR);
        }
    }; // End fu deleteNote

    const updateContentDivInnerHTML = (newInnerHTML) => {
        if (contentDiv.current) {
            contentDiv.current.innerHTML = newInnerHTML;
        }
    }; // End fu updateContentDivInnerHTML

    const checkIfAskedToEditContent = () => {
        setTimeout(() => {
            //console.log("[NancyNoteContent] Running checkIfAskedToEditContent")
            const queryParams = new URLSearchParams(location.search);
            if (queryParams.get('edit') === 'content') {
                setQuillEditorReadOnlyMode(false);
                setQuillEditorHTMLDisplay(false)
                removeEditQueryParam();
            }
        }, 500);
    } // checkIfAskedToEditContent

    useEffect(() => {
        //console.log("[NancyNoteContent] component mounted")
        checkIfAskedToEditContent();
    }, []);

    /**
     * This useEffect hook updates the quillEditorDelta state based on the NancyNoteContent prop.
     * It checks if the content is in JSON format and sets the quillEditorDelta state accordingly.
     * If the content is not in JSON format, it creates a new delta object with the content as plain text.
     * The hook runs whenever the NancyNoteContent prop changes.
     */
    useEffect(() => {
        if (NancyNoteContent) {
            //console.log("[NancyNoteContent::seEffect.note::DEBUG] note state updated. NancyNoteContent is:", NancyNoteContent);
            // Set the main content:
            if (isJsonString(NancyNoteContent)) {
                setQuillEditorDelta(JSON.parse(NancyNoteContent));
            } else {
                console.error("[NancyNoteContent::useEffect.note::ERROR] Note content is not in JSON format.");
            }
        } // if NancyNoteContent
        else {
            setQuillEditorDelta({
                ops: [{ insert: "" }],
            });
        }
    }, [NancyNoteContent]);

    /**
     * This useEffect hook sets up and removes an event listener for keydown events
     * on the Quill editor container when the editor switches between read-only and
     * editing modes. It also focuses the Quill editor when switching to editing mode,
     * allowing for the Escape key event listener to function properly and cancel editing.
     */
    useEffect(() => {
        const quillInstance = quillRef.current?.getEditor();
        if (!quillEditorReadOnlyMode && quillInstance) {
            const quillContainer = quillInstance.container;
            quillContainer.addEventListener("keydown", handleQuillEditorKeyDown);
            quillInstance.focus(); // Focus the quill editor. (Now the ESC key event listener works to cancel editing.)
            return () => { quillContainer.removeEventListener("keydown", handleQuillEditorKeyDown) };
        }
    }, [quillEditorReadOnlyMode, quillRef]);


    const enableQuillEditor = () => {
        if (quillEditorReadOnlyMode) {
            setQuillEditorReadOnlyMode(false);
            setQuillEditorHTMLDisplay(false);
        }
    }; // End fu enableQuillEditor

    const handleContentDivDoubleClick = () => {
        if (quillEditorReadOnlyMode) {
            enableQuillEditor();
        }
    }; // End fu handleContentDivDoubleClick

    const handleQuillEditorKeyDown = (e) => {
        if ( (e.ctrlKey && s_key === e.keyCode) || (e.ctrlKey && enter_key === e.keyCode) ) {
            e.preventDefault();
            //console.log("[handleQuillEditorKeyDown] Handling save from Quill editor.");
            saveContentAndSwitchToReadOnlyMode();
        } else if (escape_key === e.keyCode) {
            handleQuillCancelButtonClick();
        }
    }; // End event handler handleQuillEditorKeyDown
    
    const handleQuillCancelButtonClick = (e) => {
        setQuillEditorReadOnlyMode(true);
        setQuillEditorHTMLDisplay(true);
    }; // End event handler handleQuillSaveButtonClick

    const handleQuillSaveButtonClick = (e) => {
        saveContentAndSwitchToReadOnlyMode();
    }; // End event handler handleQuillSaveButtonClick

    const handleDeleteNoteButtonClick = (e) => {
        //console.log("[handleDeleteNoteButtonClick] Deleting note:", NancyNoteId);
        setDeleteDialogIsOpen(true);
    }; // End event handler handleDeleteNoteButtonClick
    // deleteDialogIsOpen, setDeleteDialogIsOpen
    const handleDeleteDialogAbortDelete = () => {
        setDeleteDialogIsOpen(false);
    }; // End event handler handleDeleteDialogAbortDelete
    const handleDeleteDialogConfirmDelete = () => {
        setDeleteDialogIsOpen(false);
        deleteNote();
    }

    /**
     * The return statement renders the NancyNoteContent component's JSX, which includes:
     * - A read-only div with the note content that switches to edit mode on double-click
     * - An edit button that switches the note to edit mode
     * - A ReactQuill editor instance for editing the note content
     * - Cancel and Save buttons for controlling the editor's state
     *
     * The component conditionally renders the read-only div or the ReactQuill editor
     * based on the value of quillEditorReadOnlyMode.
     */
    if( quillEditorHTMLDisplay ) {
        // Return the read-only div:
        return (
            <>
                <Box sx={{ px: 1, py: 0 }}>
                    <div
                        ref={contentDiv}
                        dangerouslySetInnerHTML={{
                            __html: convertDeltaToHtml(quillEditorDelta),
                        }}
                        onDoubleClick={handleContentDivDoubleClick}
                    />
                </Box>
                <Divider sx={{}} />
                <Box
                    display="flex"
                    alignItems="flex-end"
                    justifyContent="space-between"
                    marginTop="1em"
                    marginBottom="1em"
                >
                    <Button
                        variant="contained"
                        size="medium"
                        style={{
                            fontWeight: "bold",
                            backgroundColor: "#f0f0f0",
                            color: "#888",
                        }}
                        onClick={handleDeleteNoteButtonClick}
                    >
                        Delete
                    </Button>
                    <Dialog open={deleteDialogIsOpen} onClose={handleDeleteDialogAbortDelete}>
                        <DialogTitle>{"Confirm Delete"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to delete this note?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDeleteDialogAbortDelete}>No</Button>
                            <Button onClick={handleDeleteDialogConfirmDelete} autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Button
                        variant="contained"
                        size="medium"
                        style={{ fontWeight: "bold" }}
                        onClick={enableQuillEditor}
                    >
                        Edit
                    </Button>
                </Box>
            </>
        ); // End return
    } // end if
    else {
        // Return the Quill editor:
        return (
            <div sx={{  }}>
                {quillEditorIsSaving && 
                    <Box>
                        <CircularProgress /> Saving...
                    </Box>
                }
                <ReactQuill
                    ref={quillRef}
                    value={quillEditorDelta}
                    modules={
                        quillEditorReadOnlyMode
                            ? { toolbar: false }
                            : quillModules
                    }
                    theme="snow"
                    placeholder=""
                    readOnly={quillEditorReadOnlyMode}
                />
                <Box
                    display="flex"
                    alignItems="flex-end"
                    justifyContent="space-between"
                    marginTop="1em"
                    marginBottom="1em"
                >
                    <Button
                        variant="contained"
                        size="medium"
                        style={{
                            fontWeight: "bold",
                            backgroundColor: "#f0f0f0",
                            color: "#888",
                        }}
                        onClick={handleQuillCancelButtonClick}
                        disabled={quillEditorReadOnlyMode}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        size="medium"
                        style={{ fontWeight: "bold" }}
                        onClick={handleQuillSaveButtonClick}
                        disabled={quillEditorReadOnlyMode}
                    >
                        Save
                    </Button>
                </Box>
            </div>
        ); // End return statement
    } // End else statement
} // End Functional Component <NancyNoteContent>

